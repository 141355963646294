const initialState = {
  floorTable: {
    floor: null,
    table: null,
  },
  earnPoint: {
    enablePointRate: null,
    pointRate: 0,
  },
  farm: {
    farmName: null,
  },
  urlParams: null,
  orderId: null,
  refreshFarm: false,
  waiting: false,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FLOOR_TABLE":
      return {
        ...state,
        floorTable: action.payload,
      };

    case "REFRESH_FARM":
      return {
        ...state,
        refreshFarm: !state?.refreshFarm,
      };

    case "TOGGER_WAITING":
      return {
        ...state,
        waiting: action?.payload,
      };

    case "SET_EARN_POINT":
      return {
        ...state,
        earnPoint: action.payload,
      };

    case "SET_URL_PARAMS":
      return {
        ...state,
        urlParams: action.payload,
      };

    case "SET_ORDERID":
      return {
        ...state,
        orderId: action.payload,
      };

    case "SET_FARM":
      return {
        ...state,
        farm: action.payload,
      };

    default:
      return state;
  }
};

export default commonReducer;
