import {
  styled,
  Menu as MenuLib,
  ButtonProps,
} from "@mui/material";
import useMenu from "hooks/useMenu";
import { ReactNode, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { TextButton } from "./Button";
import { CustomMenuProps } from "types/common";

const Menu = ({
  children = [],
  buttonText = "",
  menuProps = undefined,
  buttonProps = undefined,
  value,
}: {
  children?: any[];
  buttonText?: ReactNode;
  menuProps?: CustomMenuProps;
  buttonProps?: ButtonProps;
  value?: any;
}) => {
  const { showMenu, onClickMenu, onCloseMenu, anchorEl } = useMenu();

  useEffect(() => {
    if (value) {
      onCloseMenu();
    }
  }, [value]);

  return (
    <>
      <StyledButton
        endIcon={<IoIosArrowDown />}
        variant="text"
        onClick={onClickMenu}
        focus={showMenu}
        {...buttonProps}
      >
        {buttonText}
      </StyledButton>

      <StyledMenu
        open={showMenu}
        onClose={onCloseMenu}
        anchorEl={anchorEl}
        onChange={onCloseMenu}
        {...menuProps}
      >
        {children}
      </StyledMenu>
    </>
  );
};

const StyledButton = styled(TextButton)<{ focus: boolean }>(({ focus }) => ({
  color: focus ? "var(--sub-color)" : "#667085",
  borderRadius: 8,
  lineHeight: "24px",
  svg: {
    transform: `rotate(${focus ? "-180deg" : "0deg"})`,
    transition: "all 0.2s",
  },
}));

const StyledMenu = styled(MenuLib)({
  ".MuiPaper-root": {
    padding: 16,
    width: "100%",
    maxWidth: 336,
    borderRadius: 8,
    maxHeight: 640,
  },
  ".MuiButtonBase-root": {
    padding: 10,
    borderRadius: 8,
    marginTop: 4,
    fontWeight: 600,
  },
  ".MuiList-root": {
    padding: 0,
  },
  ".Mui-selected": {
    backgroundColor: "transparent",
    color: "var(--app-sub-bg-color)",
  },
  ".Mui-selected:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  p: {
    fontWeight: 500,
  },
});

export default Menu;
