import { SVGProps } from "react";

export function ReloadIcon({
  width = 51,
  height = 50,
  viewBox = "0 0 51 50",
  stroke = "#019C3C",
  strokeWidth = 3.3,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_11874_54145)">
        <path
          d="M48.2474 8.33335V20.8333M48.2474 20.8333H35.7474M48.2474 20.8333L38.5807 11.75C36.3417 9.50983 33.5716 7.87336 30.529 6.99329C27.4865 6.11323 24.2705 6.01825 21.1813 6.71722C18.0921 7.41619 15.2303 8.88634 12.863 10.9905C10.4956 13.0946 8.69983 15.7642 7.64323 18.75M2.41406 41.6667V29.1667M2.41406 29.1667H14.9141M2.41406 29.1667L12.0807 38.25C14.3198 40.4902 17.0898 42.1267 20.1324 43.0067C23.175 43.8868 26.3909 43.9818 29.4801 43.2828C32.5693 42.5838 35.4311 41.1137 37.7985 39.0096C40.1659 36.9054 41.9616 34.2359 43.0182 31.25"
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11874_54145">
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0.330078)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
