import { Box } from "@mui/material";
import React from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

const DynamicThumbnail = ({
  classes,
  url,
  onClick,
  isVideo = false,
  att = {},
}) => {
  return (
    <Box
      className={classes}
      sx={{
        background: `url("${url}")`,
        position: "relative",
        backgroundPosition: "center",
        backgroundSize: "cover",
        ...att,
      }}
      onClick={onClick}
    >
      {isVideo && (
        <PlayCircleIcon
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </Box>
  );
};

export default DynamicThumbnail;
