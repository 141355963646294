import { FormattedMessage, useIntl } from "react-intl";

type FormattedMessageProps = {
  id?: string;
  defaultMessage?: string;
  values?: any;
  children?: any;
};

const IntlMsg = (props: FormattedMessageProps) => {
  return <FormattedMessage {...props} />;
};

const IntlText = ({ id = "" }) => {
  const intl = useIntl();
  return <>{intl.formatMessage({ id })}</>;
};

export { IntlText };

export default IntlMsg;
