import http from "services/http";

const PRE_ENDPOINT = "/api/method/freshdi";

export const apiUploadFile = (data: any) =>
  http.post(`${PRE_ENDPOINT}.services.files.upload_file`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
