import { Stack, StackProps } from "@mui/material";

const Row = (props: StackProps) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      {...props}
    />
  );
};

export default Row;
