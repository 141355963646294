// NotificationHandler.tsx
import { Snackbar, Alert } from "@mui/material";
import React, { useState } from "react";
import ReactDOM from "react-dom";

let showNotification: (message: string) => void = () => {};

const NotificationHandler = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  showNotification = (msg: string) => {
    setMessage(msg);
    setOpen(true);
  };

  return ReactDOM.createPortal(
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Alert onClose={() => setOpen(false)} severity="error">
        {message}
      </Alert>
    </Snackbar>,
    document.body
  );
};

export { showNotification };
export default NotificationHandler;
