import { useMediaQuery, useTheme } from "@mui/material";
import { BREAKPOINT_VALUES } from "constants/layout";

const useBreakpoints = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BREAKPOINT_VALUES.md));
  // const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"));
  // const isLarge = useMediaQuery(theme.breakpoints.up("md"));

  return { isMobile };
};

export default useBreakpoints;
