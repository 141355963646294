import http from "services/http";

const ENDPOINT = "/api/method/freshdi.api.web.v3.chat";

export const apiGetTwlkAccessToken = () =>
  http.get(`${ENDPOINT}.get_customer_access_token`);

export const apiGetFarmIdentifyProfile = (identity: string) =>
  http.get(`${ENDPOINT}.get_identity_profile`, { params: { identity } });

export const apiGetIdentity = (identity: string) =>
  http.get(`${ENDPOINT}.get_identity`, { params: { identity } });

export const apiGetCustomerConversations = () =>
  http.get(`${ENDPOINT}.get_customer_conversations`);
