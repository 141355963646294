import { Box, ButtonBase } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useRef, useEffect } from 'react';
import useStyles from './styles';

const Popup = ({content, changeShow, show, width, maxWidth}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            changeShow(false)
          }
      }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
    }, [ref]);
  }

  if(show) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const classes = useStyles();

  return (
    <>
      {
        show ? (
          <div className={classes.popupBox}>
            <div className={classes.box} ref={wrapperRef} style={{
              width: `${width? width: "50%"}`,
              maxWidth: `${maxWidth? maxWidth: "initial"}`
            }}>
              <Box className={classes.closeBox}>
                <ButtonBase onClick={() => changeShow(false)}>
                  <CloseIcon />
                </ButtonBase>
              </Box>
              <Box mt={2} mb={2}>
                {content}
              </Box>
              </div>
          </div>
        ) : null
      }
    </>
  )
}

export default Popup
