import http from "services/http";

const PRE_ENDPOINT = "/api/method/freshdi.api.web.v3";

export const apiGetAllUOM = () => http.get(`${PRE_ENDPOINT}.get_uom`);

export const apiGetAppConfigs = () => http.get(`${PRE_ENDPOINT}.get_variates`);

export const apiGetSitemap = () => http.get(`${PRE_ENDPOINT}.get_sitemap`);

export const apiGetCountries = () => http.get(`${PRE_ENDPOINT}.get_countries`);

export const apiGetCurrencies = () =>
  http.get(`${PRE_ENDPOINT}.get_currencies`);

export const apiGetBizType = () =>
  http.get(`${PRE_ENDPOINT}.get_business_types_select`);

export const apiGetRevenues = () =>
  http.get(`${PRE_ENDPOINT}.get_annual_revenue_select`);

export const apiGetNumEmployees = () =>
  http.get(`${PRE_ENDPOINT}.get_num_employees_select`);

export const apiSearchProductCategory = (kw: string | null) =>
  http.post(`${PRE_ENDPOINT}.search_product_category`, { kw });
