import { CircularProgress, Modal, ModalProps } from "@mui/material";
import { styled } from "@mui/system";

const Wait = (props: ModalProps) => {
  const CustomBox = styled("div")({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "&:focus": {
      outline: "none",
    },
    zIndex: 1305
  });

  return (
    <Modal open={props?.open} onClose={props?.onClose}>
      <CustomBox>
        <CircularProgress size={30} style={{ color: "#E98100" }} />
      </CustomBox>
    </Modal>
  );
};

export default Wait;
