import { Typography, TypographyProps, styled } from "@mui/material";
import { Link as LinkLib, LinkProps } from "react-router-dom";
import IntlMsg from "./IntlMsg";
import { AnchorHTMLAttributes, ComponentProps } from "react";
import { COLORS } from "constants/layout";

export const Text = (props: TypographyProps) => {
  return <Typography {...props} />;
};

export const TextMd = (props: TypographyProps) => {
  return <Typography fontWeight={500} {...props} />;
};

export const TextSemibold = (props: TypographyProps) => {
  return <Typography fontWeight={600} lineHeight="24px" {...props} />;
};

export const TextBold = (props: TypographyProps) => {
  return <Typography fontWeight={700} {...props} />;
};

export const SmText = (props: TypographyProps) => {
  return <Typography variant="body2" {...props} />;
};

export const SmTextSemibold = (props: TypographyProps) => {
  return <Typography variant="body2" fontWeight={600} {...props} />;
};

export const SmTextMd = (props: TypographyProps) => {
  return (
    <Typography variant="body2" fontWeight={500} lineHeight="20px" {...props} />
  );
};

export const SmTextBold = (props: TypographyProps) => {
  return <Typography variant="body2" fontWeight={600} {...props} />;
};

export const LXsText = (props: TypographyProps) => {
  return <Typography fontSize={13} {...props} />;
};

export const XsText = (props: TypographyProps) => {
  return <Typography fontSize={12} lineHeight="18px" {...props} />;
};

export const XsTextMd = (props: TypographyProps) => {
  return <Typography fontSize={12} fontWeight={500} {...props} />;
};

export const XsTextSemiBold = (props: TypographyProps) => {
  return <Typography fontSize={12} fontWeight={600} {...props} />;
};

export const XsTextBold = (props: TypographyProps) => {
  return <Typography fontSize={12} fontWeight={700} {...props} />;
};

export const LgText = (props: TypographyProps) => {
  return <Typography fontSize={18} {...props} />;
};

export const LgTextMd = (props: TypographyProps) => {
  return <Typography fontSize={18} fontWeight={500} {...props} />;
};

export const LgTextSemiBold = (props: TypographyProps) => {
  return <Typography fontSize={18} fontWeight={600} {...props} />;
};

export const XlText = (props: TypographyProps) => {
  return <Typography fontSize={20} {...props} />;
};

export const XlTextMd = (props: TypographyProps) => {
  return <Typography fontSize={20} fontWeight={500} {...props} />;
};

export const XlTextSemiBold = (props: TypographyProps) => {
  return <Typography fontSize={20} fontWeight={600} {...props} />;
};

export const DpTextMd = (props: TypographyProps) => {
  return <Typography fontSize={36} fontWeight={500} {...props} />;
};

export const DpTextSemiBold = (props: TypographyProps) => {
  return <Typography fontSize={36} fontWeight={600} {...props} />;
};

export const XlDpTextSemiBold = (props: TypographyProps) => {
  return (
    <Typography fontSize={60} fontWeight={600} lineHeight={1.2} {...props} />
  );
};

export const XsDpText = (props: TypographyProps) => {
  return <Typography fontSize={24} {...props} />;
};

export const XsDpTextBold = (props: TypographyProps) => {
  return <Typography fontSize={24} fontWeight={700} {...props} />;
};

export const XsDpTextSemiBold = (props: TypographyProps) => {
  return (
    <Typography fontSize={24} fontWeight={600} lineHeight="32px" {...props} />
  );
};

export const XsDpTextMd = (props: TypographyProps) => {
  return <Typography fontSize={24} fontWeight={500} {...props} />;
};

export const SmDpTextBold = (props: TypographyProps) => {
  return <Typography fontSize={30} fontWeight={700} {...props} />;
};

export const SmDpTextMd = (props: TypographyProps) => {
  return <Typography fontSize={30} fontWeight={500} {...props} />;
};

export const SmDpTextSemiBold = (props: TypographyProps) => {
  return <Typography fontSize={30} fontWeight={600} {...props} />;
};

export const SmDpTextMedium = (props: TypographyProps) => {
  return <Typography fontSize={30} fontWeight={500} {...props} />;
};

export const Link = (props: LinkProps) => {
  return <StyledLink {...props} />;
};

interface AnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {}

export const ShowResultText = styled(Text)({
  color: COLORS.gray[800],
  fontWeight: 500,
  ".qlt": {
    fontWeight: 600,
    color: "var(--yellow-500)",
    fontStyle: "italic",
  },
  ".search": {
    fontSize: 20,
    fontWeight: 500,
    color: "var(--gray-800)",
  },
});

const StyledLink = styled(LinkLib)({
  textDecoration: "none",
  color: "initial",
});

export const AppTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 600,
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));

interface ViewAllTextProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  to?: string;
  href?: string;
}

export const ViewAllText = (props: ViewAllTextProps) => {
  const { to } = props;
  const Component = to ? LinkLib : "a";

  return (
    <StyledViewAllText
      as={Component}
      className="see_all_text"
      rel="noopener noreferrer"
      {...props}
    >
      <SmText whiteSpace="nowrap">
        <IntlMsg id="view_all" />
      </SmText>
    </StyledViewAllText>
  );
};

const StyledViewAllText = styled("a")(({ theme }) => ({
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  p: {
    color: "var(--gray-800)",
  },
  [theme.breakpoints.down("md")]: {
    p: {
      fontSize: 12,
    },
  },
}));

export const OpenTabLink = (props: AnchorProps) => {
  return <StyledATag target="_blank" rel="noreferrer" {...props} />;
};

export const StyledATag = styled("a")({
  textDecoration: "none",
});

export const StyledA = styled("a")({
  textDecoration: "none",
  color: "#000",
  "&:hover": {
    textDecoration: "underline",
  },
});

export const StyledRouterA = styled(LinkLib)({
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
});
