import {
  Divider,
  InputAdornment,
  MenuItem,
  TextField,
  TextFieldProps,
  debounce,
  styled,
} from "@mui/material";
import { LuSearch } from "react-icons/lu";
import { useIntl } from "react-intl";
import Menu from "./Menu";
import {
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { DIRECT_PATHS, PATHS, SEARCH_PARAMS } from "constants/routes";
import { apiSearchProductCategory } from "services/api/helpers";
import { StyledAutocomplete } from "./Input";
import Row from "./Row";
import { useQueryParam } from "helpers/search";
import { useLocation } from "react-router-dom";
import { COLORS } from "constants/layout";
import { StyledATag, StyledRouterA, TextMd } from "./Text";
import { BaseButton, GreenButton } from "./Button";
import useBreakpoints from "hooks/useBreakpoint";

const PRODUCT_OPTION = "products";
const SUPPLIER_OPTION = "suppliers";
const RFQ_OPTION = "rfqs";
const BUYER_OPTION = "buyers";

const options = [
  { label: PRODUCT_OPTION, placeholder: "search_products" },
  { label: SUPPLIER_OPTION, placeholder: "search_suppliers" },
  { label: RFQ_OPTION, placeholder: "search_rfqs" },
  { label: BUYER_OPTION, placeholder: "search_buyers" },
];

const categories = [
  { label: "Arabica Coffee", value: "Arabica-Coffee" },
  { label: "Cashew Nuts", value: "Cashew-Nuts" },
  { label: "Fresh Vegetables", value: "Fresh-Vegetables" },
  { label: "Frozen Durian", value: "Frozen-Durian" },
  { label: "Soybean", value: "Soybean" },
];

const HeaderSearch = (props: TextFieldProps) => {
  const t = useIntl();
  const { pathname } = useLocation();
  const { isMobile } = useBreakpoints();
  const [selectedOption, setSelectedOption] = useState(
    useQueryParam(SEARCH_PARAMS.searchType) || PRODUCT_OPTION
  );
  const [searchKeyword, setSearchKeyword] = useState(
    useQueryParam(SEARCH_PARAMS.keyword) || ""
  );
  const [searchCategories, setsearchCategories] = useState<any[]>([]);
  const [searchCategoryLoading, setsearchCategoryLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const delayedFetchOptions = useCallback(
    debounce((searchText: string) => {
      getSearchCategories(searchText);
    }, 500),
    []
  );

  const getSearchCategories = (search: string | null) => {
    setsearchCategoryLoading(true);
    apiSearchProductCategory(search)
      .then((res) => {
        setsearchCategories(
          (res.data?.message?.data || []).map((c: any) => ({
            value: c.name,
            label: c.category_name,
          }))
        );
      })
      .finally(() => {
        setsearchCategoryLoading(false);
      });
  };

  const onClickSearch = (e: any) => {
    e?.preventDefault();
    const searchKeyword = inputRef.current ? inputRef.current?.value : "";
    if (searchKeyword.length > 0) {
      let selectedPathname = "";
      let selectedSearch = `${SEARCH_PARAMS.keyword}=${searchKeyword}&${SEARCH_PARAMS.searchType}=${selectedOption}`;

      switch (selectedOption) {
        case PRODUCT_OPTION:
          selectedPathname = PATHS.search;
          break;

        case SUPPLIER_OPTION:
          selectedPathname = PATHS.searchSuppliers;
          break;

        case RFQ_OPTION:
          selectedPathname = PATHS.rfqMarket;
          break;

        case BUYER_OPTION:
          selectedPathname = PATHS.buyers;
          break;

        default:
          break;
      }
      window.location.href = selectedPathname + "?" + selectedSearch;
    }
  };

  const onGetCategoryUrl = (value: string) => {
    let selectedPathname = "";

    switch (selectedOption) {
      case PRODUCT_OPTION:
        selectedPathname = PATHS.category + "/" + value;
        break;

      case SUPPLIER_OPTION:
        selectedPathname = PATHS.categorySupplier + "/" + value;
        break;

      case RFQ_OPTION:
        selectedPathname = DIRECT_PATHS.categoryRfqs(value);
        break;

      case BUYER_OPTION:
        selectedPathname = DIRECT_PATHS.categoryBuyer(value);
        break;

      default:
        break;
    }
    return selectedPathname;
  };

  const onClickCategory = (value: string) => {
    window.location.href = value;
  };

  const onAutoFillOption = () => {
    const pathsMatchSupplier = ["/suppliers"];
    const pathsMatchProducts = ["/products"];
    const pathsMatchBuyer = ["/buyers"];
    const pathsMatchSourcing = ["/sourcing-requests"];
    if (pathsMatchSupplier.some((path) => pathname.includes(path))) {
      setSelectedOption(SUPPLIER_OPTION);
    } else if (pathsMatchProducts.some((path) => pathname.includes(path))) {
      setSelectedOption(PRODUCT_OPTION);
    } else if (pathsMatchBuyer.some((path) => pathname.includes(path))) {
      setSelectedOption(BUYER_OPTION);
    } else if (pathsMatchSourcing.some((path) => pathname.includes(path))) {
      setSelectedOption(RFQ_OPTION);
    }
  };

  useEffect(() => {
    onAutoFillOption();
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      getSearchCategories(null);
    }, 2000);
  }, []);

  return (
    <StyledForm className="search-box">
      <StyledAutocomplete
        freeSolo
        options={searchCategories}
        getOptionLabel={(option: any) => option.label || ""}
        loading={searchCategoryLoading}
        onChange={(e, newValue: any) => {
          if ((e as KeyboardEvent).key === "Enter") {
            onClickSearch(e);
          } else {
            onClickCategory(onGetCategoryUrl(newValue?.value));
          }
        }}
        inputValue={searchKeyword}
        onInputChange={(e, newInputValue) => {
          setSearchKeyword(newInputValue);
          delayedFetchOptions(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...{ ...params, ...props }}
            placeholder={t.formatMessage({
              id: "what_are_you_looking_for",
            })}
            fullWidth
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Row className="start-ador" spacing={1}>
                    <Menu
                      buttonText={t.formatMessage({ id: selectedOption })}
                      buttonProps={{ className: "choose-option" }}
                      value={selectedOption}
                    >
                      {options.map(({ label }) => (
                        <MenuItem
                          key={label}
                          selected={label === selectedOption}
                          onClick={() => setSelectedOption(label)}
                        >
                          {t.formatMessage({ id: label })}
                        </MenuItem>
                      ))}
                    </Menu>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <LuSearch onClick={onClickSearch} className="search-icon" />
                  </Row>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <GreenButton
                    className="search-btn"
                    onClick={(e) => onClickSearch(e)}
                  >
                    {t.formatMessage({ id: "search" })}
                  </GreenButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      {isMobile && (
        <Row
          flexWrap="nowrap"
          className="categories scroll-box hor-scroll-box"
          mt={1}
          spacing={{ xs: 1, md: 2 }}
        >
          {categories.map(({ value, label }) => (
            <StyledATag key={value} href={onGetCategoryUrl(value)}>
              <BaseButton className={`category_box chip `}>{label}</BaseButton>
            </StyledATag>
          ))}
        </Row>
      )}
    </StyledForm>
  );
};

export const StyledForm = styled("div")(({ theme }) => ({
  flex: 1,
  // maxWidth: 520,
  "& input": {
    paddingLeft: 0,
    color: "#667085",
  },
  "& .MuiInputAdornment-root": {
    cursor: "pointer",
  },
  ".choose-option": {
    fontSize: 14,
    padding: "8px 0",
  },
  ".MuiInputBase-root": {
    padding: "10px 14px",
    paddingRight: "0 !important",
    borderRadius: 12,
  },
  ".MuiDivider-root": {
    borderColor: COLORS.green[500],
  },
  ".search-btn": {
    fontWeight: 500,
    borderRadius: "0 12px 12px 0",
    height: 43,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
      borderColor: COLORS.green[500],
    },
    "&": {
      boxShadow: `0 0 6px 4px ${COLORS.green[25]}`,
    },
  },
  "& .MuiSelect-icon": {
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    "& input": {
      fontSize: 12,
    },
    ".search-btn": {
      height: 35,
    },
    ".MuiButtonBase-root": {
      lineHeight: "18px",
    },
  },
}));

export default HeaderSearch;
