import { BoxProps } from "@mui/material";
import Footer from "Components/Footer";
import Header from "Components/Header";
import Main from "Components/Main";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  mainprops?: BoxProps;
  hideSearch?: boolean;
  isWorkSpace?: boolean;
  isHomePage?: boolean;
};

const Layout = (props: Props) => {
  const { children, hideSearch, isWorkSpace, isHomePage } = props;

  return (
    <>
      {/* {currentPlan.plan_name !== PLAN_NAMES.trial && <TryTrialPlanBanner />} */}
      <Header {...{ hideSearch, isWorkSpace, isHomePage }} />
      <Main {...props.mainprops}>{children}</Main>
      <Footer />
    </>
  );
};

export default Layout;
