import { IconButton, Stack, styled } from "@mui/material";
import { PostType } from "types/post";
import { Img } from "./Img";
import { formatImgUrl } from "utils/helpers";
import Row from "./Row";
import {
  Link,
  SmText,
  SmTextMd,
  SmTextSemibold,
  XsText,
  XsTextSemiBold,
} from "./Text";
import { LuDot } from "react-icons/lu";
import { FiMoreVertical } from "react-icons/fi";
import { useNotify } from "providers/notify";
import { useIntl } from "react-intl";
import TimeDiff from "./TimeDiff";
import dayjs from "dayjs";
// import Attachment from "./Attachment";
import SwipeMedias from "./SwipeMedias";
import { DIRECT_PATHS } from "constants/routes";

export interface IPostProps {
  post: PostType;
}

export default function Post({ post }: IPostProps) {
  const {
    farm_avatar,
    title,
    farm_name,
    date_posted,
    creator,
    farm_item,
    farm_item_name,
    farm_item_image,
    attachments = [],
    unit,
    product_category,
    content,
    currency,
    offers = [],
    farm_id,
  } = post;
  const { setNotify } = useNotify();
  const t = useIntl();

  const onClickViewMore = () => {
    setNotify({
      open: true,
      type: "info",
      msg: t.formatMessage({ id: "coming_soon" }),
    });
  };

  return (
    <StyledStack className="border card" spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Link to={DIRECT_PATHS.supplierDetail(farm_id)}>
          <Img
            src={formatImgUrl(farm_avatar, 1)}
            alt="img"
            className="company-avatar"
          />
        </Link>
        <Row justifyContent="space-between" flex={1}>
          <Stack spacing={0.5}>
            <Link to={DIRECT_PATHS.supplierDetail(farm_id)}>
              <SmTextSemibold>{farm_name}</SmTextSemibold>
            </Link>
            <Row spacing={0.5} color="var(--gray)">
              <XsTextSemiBold>{creator}</XsTextSemiBold>
              <LuDot />
              <XsText>
                <TimeDiff
                  startTime={dayjs(date_posted).format()}
                  endTime={dayjs().format()}
                />
                {t.formatMessage({ id: "ago" })}
              </XsText>
            </Row>
          </Stack>
          <IconButton onClick={onClickViewMore}>
            <FiMoreVertical className="more-icon" />
          </IconButton>
        </Row>
      </Stack>

      <SmTextMd>{title}</SmTextMd>

      {farm_item && (
        <Stack
          direction="row"
          color="var(--gray)"
          spacing={1}
          alignItems="center"
        >
          {farm_item_image && (
            <Img
              src={formatImgUrl(farm_item_image, 2)}
              className="company-avatar"
            />
          )}{" "}
          <Stack width="calc(100% - 48px)">
            <SmText className="ellipsis-text">{farm_item_name}</SmText>
          </Stack>
        </Stack>
      )}

      {product_category.value && (
        <SmText className="gray-text ellipsis-text">
          {t.formatMessage({ id: "product_category" })}:{" "}
          <span>{product_category?.label}</span>
        </SmText>
      )}

      {offers.map((o_price, i) => {
        const { price, quantity_min, quantity_max, weight } = o_price;

        return (
          <Row spacing={1} key={i}>
            {quantity_min && (
              <SmText className="desc-text ellipsis-text">
                {t.formatMessage({ id: "quantity" })}:{" "}
                <span>
                  {quantity_min}
                  {quantity_max && `-${quantity_max}`} {weight}
                </span>
              </SmText>
            )}

            {price && (
              <SmText className="desc-text ellipsis-text">
                {t.formatMessage({ id: "price" })}:{" "}
                <span>
                  {price} {currency.label}
                </span>
              </SmText>
            )}
          </Row>
        );
      })}

      {/* {Number(moq) > 0 && (
        <SmText className="desc-text ellipsis-text">
          {t.formatMessage({ id: "moqs" })}: {moq} {moq_unit}
        </SmText>
      )}
      {(price_min || price_max) && (
        <SmText className="desc-text ellipsis-text">
          {t.formatMessage({ id: "price" })}: {price_min ? `$${price_min}` : ""}
          {price_max > price_min ? `-$${price_max}` : ""}
        </SmText>
      )}

      <Row spacing={1} flexWrap="wrap">
        {quantity && (
          <SmText className="gray-text ellipsis-text">
            {t.formatMessage({ id: "quantity" })}:{" "}
            <span>
              {quantity} {unit?.label}
            </span>
          </SmText>
        )}
        {price && (
          <SmText className="gray-text ellipsis-text">
            {t.formatMessage({ id: "price" })}:{" "}
            <span>
              {price} {currency?.label}
            </span>
          </SmText>
        )}
      </Row> */}

      {content && <SmText className="gray-text">{content}</SmText>}

      <SwipeMedias medias={attachments} />
    </StyledStack>
  );
}

const StyledStack = styled(Stack)({
  ".gray-text": {
    color: "var(--gray)",
    span: {
      color: "#101828",
      fontWeight: 500,
    },
  },
  ".desc-text": {
    color: "var(--gray-500)",
    span: {
      color: "var(--gray-900)",
      fontWeight: 500,
    },
  },
  ".company-avatar": {
    width: 40,
    minWidth: 40,
    height: 40,
    objectFit: "cover",
  },
  p: {
    wordBreak: "break-all",
  },
});
