import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";

import useStyles from "./styles";

const LineBreak = (props) => {
  const classes = useStyles();
  const { hiddenDot = true, att, lineWidth = 8, mainRef } = props;
  const [numberOfDashes, setNumberOfDashes] = useState(20);

  useEffect(() => {
    setNumberOfDashes(
      Math.ceil((mainRef?.current?.clientWidth || 0) / (lineWidth + 8))
    );
  }, [mainRef]);

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        position="relative"
        sx={{ "& span": { display: hiddenDot ? "none" : "block" }, ...att }}
      >
        {new Array(numberOfDashes).fill(0).map((item, index) => (
          <div
            className={classes.line}
            key={index}
            style={{ width: `${lineWidth}px` }}
          />
        ))}
      </Stack>
    </>
  );
};

export default LineBreak;
