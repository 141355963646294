import { Box, Stack, Typography } from "@mui/material";
// import { FiShoppingCart, FiUser } from "react-icons/fi";

import { ProductType } from "types";
import { Img } from "./Img";
import { CIRCLE_CROWN_ICON, LikeIcon, VERIFIED2_ICON } from "Assets/icons";
import { formatImgUrl } from "utils/helpers";
import { ReactNode, forwardRef, useMemo } from "react";
import Row from "./Row";
import { DIRECT_PATHS } from "constants/routes";
import { formatCurrencyWithUnit } from "helpers/string";
import { useIntl } from "react-intl";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import {
  LgTextSemiBold,
  StyledA,
  StyledRouterA,
  XsText,
  XsTextMd,
} from "./Text";
import { StyledProduct } from "./Product.styles";
import { getCountryFlag } from "helpers/address";
import TimeDiff from "./TimeDiff";
import dayjs from "dayjs";
import { COLORS } from "constants/layout";
import { useParams } from "react-router-dom";
import { ParamsType } from "types/params";
import { PlanChip } from "./Plan";
import { isWithinLastNDays } from "helpers/datetime";
import { VerificationTooltip, VerifyTooltip } from "./Tooltip";

type Props = {
  product?: ProductType;
  swiper?: boolean;
  hideCompanyInfo?: boolean;
};

const Product = forwardRef(
  ({ product, swiper = true, hideCompanyInfo = false }: Props, ref: any) => {
    const t = useIntl();
    const now = dayjs();
    const { categoryId } = useParams<ParamsType>();
    const { downTabletBreakpoint: isMobile } = useMediaBreakpoints();
    const {
      price_min = 0,
      price_max = 0,
      moq_unit = "",
      moq,
      farm_id = "",
      country_code,
      is_featured,
      price_status,
      last_updated,
      country_path = "",
      country,
      plan_name,
      is_verified_work_email,
      is_biz_verified,
    } = product || {};

    const priceStatusConfigs = useMemo(() => {
      switch (price_status) {
        case "COMPETITIVE":
          return { label: "good_price" };

        default:
          return {};
      }
    }, [price_status]);

    const isNew = useMemo(
      () => last_updated && isWithinLastNDays(last_updated, 7),
      [last_updated]
    );

    return (
      <StyledProduct swiper={swiper} ref={ref} className="product">
        <Box className="image">
          <Img
            src={formatImgUrl(product?.farm_item_image, 3)}
            alt="img"
            height="100%"
            width="100%"
          />
        </Box>
        <Box className="info_box">
          <StyledRouterA to={`/${product?.slug || ""}`}>
            <Typography className="name ellipsis-text" component="h2">
              {isNew && <span>{t.formatMessage({ id: "new" })}</span>}
              {product?.farm_item_name}
            </Typography>
          </StyledRouterA>
          <div>
            <LgTextSemiBold className="price ellipsis-text">
              {price_min <= 0 && t.formatMessage({ id: "to_be_negotiated" })}
              {price_min > 0 &&
                `${formatCurrencyWithUnit(price_min)} ${
                  price_min < price_max
                    ? ` - ${formatCurrencyWithUnit(price_max)}`
                    : ""
                }`}
            </LgTextSemiBold>
            {priceStatusConfigs.label && (
              <XsTextMd className="price-status">
                {priceStatusConfigs.label && (
                  <>
                    <LikeIcon />
                    {t.formatMessage({ id: priceStatusConfigs.label })}
                  </>
                )}
              </XsTextMd>
            )}
          </div>
          {moq && (
            <Typography className="min_order_qlt ellipsis-text">
              {t.formatMessage({ id: "min_order" })}:{" "}
              <span>
                {moq} {moq_unit}
              </span>
            </Typography>
          )}

          {/* plan + verified */}
          <Row spacing={0.5} mb={0.5} minHeight={20}>
            <PlanChip plan={plan_name} height={16} />
            {is_biz_verified || is_verified_work_email ? (
              <VerifyTooltip
                title={
                  <VerificationTooltip
                    data={{ is_biz_verified, is_verified_work_email }}
                  />
                }
              >
                <Img
                  src={VERIFIED2_ICON}
                  alt="icon"
                  className="verified-icon"
                  height={16}
                />
              </VerifyTooltip>
            ) : null}
          </Row>

          {!hideCompanyInfo && (
            <Stack spacing={0.5}>
              <StyledRouterA to={DIRECT_PATHS.supplierDetail(farm_id)}>
                <XsTextMd
                  color={COLORS.gray[800]}
                  className="ellipsis-text"
                  component="h3"
                >
                  {product?.farm_name}
                </XsTextMd>
              </StyledRouterA>
              <Row spacing={1} className="company_info" height={18}>
                {country_code && (
                  <Img src={getCountryFlag(country_code).src} alt="flag" />
                )}
                <StyledA
                  href={
                    categoryId
                      ? `${DIRECT_PATHS.category(categoryId)}/${country_path}`
                      : ""
                  }
                >
                  <XsText className="cutoff-text oneline" component="h4">
                    {country}
                  </XsText>
                </StyledA>
              </Row>
            </Stack>
          )}
        </Box>

        {/* sticky status box */}
        <Stack className="stick" spacing={0.5} alignItems="flex-end">
          {/* {fd_country && <CountryFlag {...{ fd_country, country_code }} />} */}
          {is_featured === 1 && (
            <XsTextMd className="sponsored status-label yellow-label">
              <Img src={CIRCLE_CROWN_ICON} alt="icon" />
              {!isMobile && t.formatMessage({ id: "sponsored" })}
            </XsTextMd>
          )}
        </Stack>

        {last_updated && (
          <Typography className="last-updated ellipsis-text" mb={1}>
            {t.formatMessage(
              { id: "last_updated_value" },
              {
                s: (code: ReactNode) => <span>{code}</span>,
                value: (
                  <TimeDiff
                    startTime={dayjs(last_updated).format()}
                    endTime={now.format()}
                  />
                ),
              }
            )}
          </Typography>
        )}
      </StyledProduct>
    );
  }
);

export default Product;
