const formatError = (err: any) => {
  try {
    const errorRes = (JSON.parse(err?.response?.data?._server_messages) ||
      "[{}]")[0];
    return JSON.parse(errorRes) || {};
  } catch (error) {
    return {};
  }
};

export { formatError };
