const initialState = {
  selectedVoucher: null
}

const voucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_VOUCHER':
      return {
        ...state,
        selectedVoucher: action?.payload
      }
      
    default:
      return state
  }
}

export default voucherReducer
