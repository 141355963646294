import axios from "axios";
import { getConfigs } from "../Config";

export const configURL = getConfigs();

// Get trace combo information
export const getTraceCombo = (combo_name, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.trace_combo.get_combo",
    combo_n: combo_name,
  };
  return axios(
    `${query_data.server}${query_data.end_point}?combo_name=${query_data.combo_n}`,
    { headers: { Authorization: configURL.TOKEN } }
  )
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Get combo item
export const getComboItem = (callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.item.get_combo_item",
  };
  return axios(`${query_data.server}${query_data.end_point}`, {
    headers: { Authorization: configURL.TOKEN },
  })
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Get combo item detail
export const getComboItemDetail = (item_code, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.item.get_combo_item_detail",
  };
  return axios(
    `${query_data.server}${query_data.end_point}?item_name=${item_code}`,
    { headers: { Authorization: configURL.TOKEN } }
  )
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// API template
export const APITemplate = async (end_point, data, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: end_point,
  };
  return await axios
    .post(`${query_data.server}${query_data.end_point}`, data, {
      headers: { Authorization: configURL.TOKEN },
    })
    .then((response) => {
      if (callBack) {
        callBack(response?.data);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Post comment image
export const PostCultivationCommentImage = (
  images,
  cultivation,
  callBack,
  errorCallBack
) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.cultivation.upload_file",
  };
  let data = new FormData();
  data.append("file", images);
  data.append("docname", cultivation);
  return axios
    .post(`${query_data.server}${query_data.end_point}`, data, {
      headers: { Authorization: configURL.TOKEN },
    })
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Post comment cultivation
export const PostCultivationComment = (comment, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.cultivation.post_cultivation_comment",
  };
  return axios
    .post(`${query_data.server}${query_data.end_point}`, comment, {
      headers: { Authorization: configURL.TOKEN },
    })
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Post reqest buy
export const PostRequestBuy = (contactInfo, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.trace.cultivation.post_request_buy",
  };
  return axios
    .post(`${query_data.server}${query_data.end_point}`, contactInfo, {
      headers: { Authorization: configURL.TOKEN },
    })
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Post follower information
export const PostFollowerInfo = (followerInfo, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.cultivation.post_follower_info",
  };
  return axios
    .post(`${query_data.server}${query_data.end_point}`, followerInfo, {
      headers: { Authorization: configURL.TOKEN },
    })
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Register customer
export const RegisterCustomer = (userInfo, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.customer.register",
  };
  return axios
    .post(`${query_data.server}${query_data.end_point}`, userInfo, {
      headers: { Authorization: configURL.TOKEN },
    })
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Check sale order
export const CheckSaleOrder = (sale_order, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.sale_order.check_sale_order",
  };
  return axios(
    `${query_data.server}${query_data.end_point}?sale_order=${sale_order}`,
    { headers: { Authorization: configURL.TOKEN } }
  )
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Get sale order information
export const getSaleOrder = (sale_order, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.sale_order.get_sale_order",
  };
  return axios(
    `${query_data.server}${query_data.end_point}?sale_order=${sale_order}`,
    { headers: { Authorization: configURL.TOKEN } }
  )
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Post sale order
export const PostSaleOrder = (orderDetail, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.sale_order.post_sale_order",
  };
  return axios
    .post(`${query_data.server}${query_data.end_point}`, orderDetail, {
      headers: { Authorization: configURL.TOKEN },
    })
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Get customer profile
export const GetCustomerProfile = (customer_id, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.customer.get_profile",
  };
  return axios
    .post(`${query_data.server}${query_data.end_point}`, customer_id, {
      headers: { Authorization: configURL.TOKEN },
    })
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Send feedback
export const SendFeedback = (content, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.customer.send_feedback",
  };
  return axios
    .post(`${query_data.server}${query_data.end_point}`, content, {
      headers: { Authorization: configURL.TOKEN },
    })
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

// Check feedback
export const CheckFeedback = (content, callBack, errorCallBack) => {
  const query_data = {
    server: configURL.SERVER_URL,
    end_point: "freshdi.data.customer.check_feedback",
  };
  return axios
    .post(`${query_data.server}${query_data.end_point}`, content, {
      headers: { Authorization: configURL.TOKEN },
    })
    .then((response) => {
      if (callBack) {
        callBack(response["data"]);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};

export const getBlogPosts = ({ lang = "vi", category = "all" }) => {
  return axios.get(
    `${configURL.SERVER_URL}freshdi.data.web.blog.freshdi_blog.get_blogs`,
    {
      headers: { Authorization: configURL.TOKEN },
      params: {
        lang,
        category: category === "all" ? undefined : category,
      },
    }
  );
};

export const getBlogDetail = ({ lang, category, code }) => {
  return axios.get(
    `${configURL.SERVER_URL}freshdi.data.web.blog.freshdi_blog.get_blog_detail`,
    {
      headers: { Authorization: configURL.TOKEN },
      params: {
        lang,
        category,
        code,
      },
    }
  );
};

export const getBlogCategories = ({ lang = "vi" }) => {
  return axios.get(
    `${configURL.SERVER_URL}freshdi.data.web.blog.freshdi_blog.get_blog_category`,
    {
      headers: { Authorization: configURL.TOKEN },
      params: {
        lang,
      },
    }
  );
};
