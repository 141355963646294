import { Box, Modal } from '@mui/material';
import React from 'react';
import useStyles from './styles';

const CustomModal = ({showModal, closeModal, content, att}) => {
  const classes = useStyles();

  return (
    <>
      <Modal open={showModal} onClose={closeModal} sx={{...att}}>
        <Box className={classes.box}>
          {content}
        </Box>
      </Modal>
    </>
  )
}

export default CustomModal
