import { Container, Divider, Grid, Stack, styled } from "@mui/material";
import React, { memo } from "react";
import { SmText, StyledRouterA, TextMd } from "./Text";
import { useIntl } from "react-intl";
import { GreenButton } from "./Button";
import { COLORS } from "constants/layout";
import Row from "./Row";
import { PATHS, SEARCH_PARAMS } from "constants/routes";
import { objectToQueryString } from "helpers/search";
import { encodeBase64 } from "helpers/base64";
import { getUser } from "redux/selectors/user";
import { useSelector } from "react-redux";
import { UserType } from "types/user";

type Props = {};

const FooterEnterprise = (props: Props) => {
  const t = useIntl();
  const user: UserType = useSelector(getUser);
  const { is_logged_in } = user || {};

  const questions = {
    what_is_freshdi: {
      title: "what_is_freshdi",
      description: "what_is_freshdi_description_1",
    },
    what_makes_freshdi_different: {
      title: "what_makes_freshdi_different",
      description: "what_makes_freshdi_different_description_1",
    },
    ai_different: {
      title: "why_is_freshdi_ai_different_from_traditional_platforms",
      descriptions: [
        "why_is_freshdi_ai_different_from_traditional_platforms_description_1",
        "why_is_freshdi_ai_different_from_traditional_platforms_description_2",
        "why_is_freshdi_ai_different_from_traditional_platforms_description_3",
        "why_is_freshdi_ai_different_from_traditional_platforms_description_4",
      ],
    },
    how_to_use_freshdi_ai: {
      title: "how_to_use_freshdi_ai",
      descriptions: [
        "how_to_use_freshdi_ai_description_1",
        "how_to_use_freshdi_ai_description_2",
        "how_to_use_freshdi_ai_description_3",
        "how_to_use_freshdi_ai_description_4",
      ],
    },
    q_and_a: {
      title: "q_and_a",
      descriptions: ["qa_question_1", "qa_question_2", "qa_question_3"],
    },
  };

  return (
    <Container maxWidth="xl">
      {(!is_logged_in || (user.is_supplier && is_logged_in)) && (
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          mb={2}
          justifyContent="center"
        >
          <Row
            direction={{ xs: "column", md: "row" }}
            spacing={1}
            alignItems={{ xs: "flex-start", md: "center" }}
          >
            <TextMd className="title">
              {t.formatMessage({ id: "are_you_an_enterprise" })}
            </TextMd>
            <SmText className="description">
              {t.formatMessage({ id: "are_you_an_enterprise_desc" })}
            </SmText>
          </Row>
          <Row justifyContent="center">
            <StyledRouterA
              to={{
                pathname: is_logged_in ? PATHS.startSelling : PATHS.register,
                search: is_logged_in
                  ? ""
                  : objectToQueryString({
                      [SEARCH_PARAMS.directUrl]: encodeBase64(
                        window.location.href
                      ),
                    }),
              }}
            >
              <GreenButton sx={{ whiteSpace: "nowrap" }}>
                {t.formatMessage({ id: "list_my_company" })}
              </GreenButton>
            </StyledRouterA>
          </Row>
        </Stack>
      )}

      <Divider />

      <StyledGrid container spacing={{ xs: 2, md: 4 }} py={2}>
        <Grid item xs={12} md={6}>
          <TextMd mb={1} className="title">
            {t.formatMessage({ id: questions.what_is_freshdi.title })}
          </TextMd>
          <SmText color={COLORS.gray[500]} className="description">
            {t.formatMessage({ id: questions.what_is_freshdi.description })}
          </SmText>

          <TextMd mb={1} mt={2} className="title">
            {t.formatMessage({
              id: questions.what_makes_freshdi_different.title,
            })}
          </TextMd>
          <SmText color={COLORS.gray[500]} className="description">
            {t.formatMessage({
              id: questions.what_makes_freshdi_different.description,
            })}
          </SmText>

          <TextMd mb={1} mt={2} className="title">
            {t.formatMessage({
              id: questions.ai_different.title,
            })}
          </TextMd>
          <ul>
            {questions.ai_different.descriptions.map((item, i) => (
              <li key={i}>
                <SmText color={COLORS.gray[500]} className="description">
                  {t.formatMessage({ id: item })}
                </SmText>
              </li>
            ))}
          </ul>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextMd mb={1} className="title">
            {t.formatMessage({
              id: questions.how_to_use_freshdi_ai.title,
            })}
          </TextMd>
          <ul>
            {questions.how_to_use_freshdi_ai.descriptions.map((item, i) => (
              <li key={i}>
                <SmText color={COLORS.gray[500]} className="description">
                  {t.formatMessage({ id: item })}
                </SmText>
              </li>
            ))}
          </ul>

          <TextMd mb={1} mt={2} className="title">
            {t.formatMessage({
              id: questions.q_and_a.title,
            })}
          </TextMd>
          <ul>
            {questions.q_and_a.descriptions.map((item, i) => (
              <li key={i}>
                <SmText color={COLORS.gray[500]} className="description">
                  {t.formatMessage({ id: item })}
                </SmText>
              </li>
            ))}
          </ul>
        </Grid>
      </StyledGrid>
    </Container>
  );
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  ul: {
    paddingInlineStart: 16,
  },
  ".description": {
    textAlign: "justify",
  },

  [theme.breakpoints.down("md")]: {
    ".title": {
      fontSize: 14,
    },
    ".description": {
      fontSize: 12,
    },
  },
}));

export default memo(FooterEnterprise);
