import http from "services/http";

const PRE_ENDPOINT = "/api/method/freshdi.api.web.v3";

export const apiGetFeaturedBuyers = (data?: any) =>
  http.post(`${PRE_ENDPOINT}.listing.get_featured_buyers`, data);

export const apiGetMyQuotations = (data?: any) =>
  http.get(`${PRE_ENDPOINT}.buyer.get_my_quotations`, { params: data });

export const apiGetOverviewStatistic = () =>
  http.get(`${PRE_ENDPOINT}.buyer.get_overview_statistic`);

export const apiMarkQuotationAsSeen = () =>
  http.post(`${PRE_ENDPOINT}.buyer.mark_quotation_as_seen`);

export const apiSendInterestedQuotation = (quotation_id: string) =>
  http.post(`${PRE_ENDPOINT}.buyer.interested_quotation`, { quotation_id });

export const apiGetCountNotiMenuBar = () =>
  http.get(`${PRE_ENDPOINT}.buyer.count_noti_menu_bar`);
