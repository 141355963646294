import { Box, Stack, StackProps, styled } from "@mui/material";

export const StyledHeader = styled(Stack)(({ theme }) => ({
  position: "sticky",
  top: 0,
  paddingTop: 12,
  backgroundColor: "#fff",
  zIndex: 10,
  "& a .logo": {
    height: 40,
  },
  ".search-box": {
    maxWidth: 720,
  },
  ".bottom-box": {
    transition: "opacity 0.4s ease, height 0.3s ease",
    "&.visible": {
      opacity: 1,
      height: 41,
    },
    "&.hidden": {
      opacity: 0,
      height: 0,
    },
  },
  [theme.breakpoints.down("md")]: {
    padding: "8px 0",
    "& a .logo": {
      height: 32,
    },
  },
}));

export const StyledMobileHeader = styled("div")({
  ".mobileheader .service_link": {
    flexDirection: "row-reverse",
    gap: 12,
    svg: {
      // display: "none",
    },
    ".label": {
      marginTop: 0,
      fontSize: 16,
      fontWeight: 600,
      color: "#667085",
    },
  },
  ".quickrfq_button": {
    color: "var(--color)",
    width: "fit-content",
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  ".selling-btn": {
    fontSize: 14,
  },
});

export const StyledCenterStack = styled((props: StackProps) => (
  <Stack direction="row" alignItems="center" {...props} />
))({
  "& .user_btn": {
    "& svg": {
      height: 32,
      width: 32,
    },
  },
});

export const StyledTopCategorys = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 8,
  flexWrap: "wrap",
  ".selected": {
    color: "var(--app-sub-bg-color)",
  },

  [theme.breakpoints.down("md")]: {
    flexWrap: "nowrap",
  },
}));
