import { Snackbar, SnackbarProps, styled } from "@mui/material";
import IntlMsg from "./IntlMsg";

const SnackbarNotify = (props: SnackbarProps) => {
  return (
    <StyledSnackbar
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      message={<IntlMsg id="Msg.updateSuccessful" />}
      {...props}
    />
  );
};

const StyledSnackbar = styled(Snackbar)({
  bottom: 0,
});

export { SnackbarNotify };
