import { SVGProps } from "react";

export function LikeIcon({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  fill = "#F04438",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M16 7.14262C16 6.75685 15.8468 6.38688 15.574 6.1141C15.3012 5.84132 14.9312 5.68808 14.5455 5.68808H9.94909L10.6473 2.36444C10.6618 2.29171 10.6691 2.21171 10.6691 2.13171C10.6691 1.83353 10.5455 1.55717 10.3491 1.3608L9.57818 0.597168L4.79273 5.38262C4.52364 5.65171 4.36364 6.01535 4.36364 6.41535V13.6881C4.36364 14.0738 4.51688 14.4438 4.78966 14.7166C5.06244 14.9894 5.43241 15.1426 5.81818 15.1426H12.3636C12.9673 15.1426 13.4836 14.779 13.7018 14.2554L15.8982 9.12808C15.9636 8.9608 16 8.78626 16 8.59717V7.14262ZM0 15.1426H2.90909V6.41535H0V15.1426Z"
        fill={fill}
      />
    </svg>
  );
}
