import { Tabs as TabsLib, styled } from "@mui/material";
import { COLORS } from "constants/layout";

const Tabs = styled(TabsLib)({
  minHeight: 36,
  borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
  ".MuiTabs-flexContainer": {},
  ".MuiButtonBase-root": {
    padding: "5px 16px",
    textTransform: "initial",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    color: COLORS.gray[600],
    minHeight: 30,
  },
  ".MuiButtonBase-root.Mui-selected, .MuiButtonBase-root.Mui-selected:hover": {
    color: COLORS.gray[900],
  },
  ".MuiButtonBase-root:hover": {
    color: COLORS.gray[900],
  },
  // ".MuiTabs-scroller": {
  //   padding: "4px 0",
  // },
  ".MuiTabs-indicator": {
    backgroundColor: COLORS.green[700],
  },
  ".MuiButtonBase-root:not(last-child)": {},
});

export default Tabs;
