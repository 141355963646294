import { debounce, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BelowChips, Input, InputProp, StyledAutocomplete } from "./Input";
import { OptionType } from "types";

type Props = {
  autocompleteProps?: { value: any; onChange: any; sx?: any };
  inputProps?: InputProp;
  apiSearch?: (data: any) => Promise<any>;
  onDeleteSelect?: (value: any) => void;
  formatData?: (data: any) => any;
};

const SearchAutocomplete = (props: Props) => {
  const { autocompleteProps, onDeleteSelect, apiSearch, formatData } = props;
  const [searchOptions, setsearchOptions] = useState<OptionType[]>([]);
  const [searchLoading, setsearchLoading] = useState(false);

  const delayedFetchOptions = debounce((searchText: string) => {
    getSearchOptions(searchText);
    // setSearchKeyword(searchKeyword);
  }, 500);

  const getSearchOptions = (search: string | null) => {
    setsearchLoading(true);
    apiSearch?.(search)
      .then((res) => {
        setsearchOptions(formatData ? formatData(res.data) : []);
      })
      .finally(() => {
        setsearchLoading(false);
      });
  };

  useEffect(() => {
    getSearchOptions?.(null);
  }, []);

  return (
    <Stack spacing={1}>
      <StyledAutocomplete
        multiple
        options={searchOptions}
        getOptionLabel={(option: any) => option.label || ""}
        loading={searchLoading}
        {...props.autocompleteProps}
        value={autocompleteProps?.value}
        renderTags={() => null}
        filterSelectedOptions
        renderInput={(params) => (
          <Input
            {...params}
            fullWidth
            {...props.inputProps}
            onChange={(e) => {
              delayedFetchOptions(e.target.value);
            }}
          />
        )}
      />
      <BelowChips
        customValue={autocompleteProps?.value}
        options={searchOptions}
        {...{ onDeleteSelect }}
      />
    </Stack>
  );
};

export { SearchAutocomplete };
