const initialState = {
  show: false,
  content: null
}
const popupReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW':
      return {
        ...state,
        show: action.payload.show,
        content: action.payload.show
      }
      
      default:
      return state
  }
}

export default popupReducer
