import { LOGOUT_USER, SET_USER } from "./actionTypes";

export const setUser = (data: any) => {
  return {
    type: SET_USER,
    payload: data,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};
