import { SVGProps } from "react";

export function SettingIcon({
  width = 21,
  height = 21,
  viewBox = "0 0 21 21",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M12.7001 5.29974C12.5169 5.48666 12.4142 5.73798 12.4142 5.99974C12.4142 6.26149 12.5169 6.51281 12.7001 6.69974L14.3001 8.29974C14.487 8.48296 14.7383 8.58559 15.0001 8.58559C15.2619 8.58559 15.5132 8.48296 15.7001 8.29974L19.4701 4.52974C19.9729 5.64092 20.1252 6.87897 19.9066 8.07888C19.6879 9.27879 19.1088 10.3836 18.2464 11.246C17.3839 12.1084 16.2792 12.6876 15.0792 12.9062C13.8793 13.1248 12.6413 12.9726 11.5301 12.4697L4.6201 19.3797C4.22227 19.7776 3.68271 20.0011 3.1201 20.0011C2.55749 20.0011 2.01792 19.7776 1.6201 19.3797C1.22227 18.9819 0.998779 18.4423 0.998779 17.8797C0.998779 17.3171 1.22227 16.7776 1.6201 16.3797L8.5301 9.46974C8.02726 8.35855 7.87502 7.1205 8.09364 5.92059C8.31227 4.72068 8.89139 3.61589 9.75382 2.75346C10.6163 1.89102 11.721 1.3119 12.921 1.09328C14.1209 0.874651 15.3589 1.0269 16.4701 1.52974L12.7101 5.28974L12.7001 5.29974Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
