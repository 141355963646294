import React, { ReactNode, Suspense } from "react";
import { Header, LeftBanner } from "./components";
import { Box, Container, styled } from "@mui/material";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { PreloadImgs } from "Components";
import { APP_IMGS } from "Assets/images";
import { useLanguageContext } from "Components/LanguageProvider";

type Props = { children?: ReactNode; hideBanner?: boolean };

const AuthLayout = ({ children, hideBanner }: Props) => {
  const { isMobile } = useMediaBreakpoints();
  const { language } = useLanguageContext();

  return (
    <Box bgcolor="#fff">
      <PreloadImgs
        images={[
          APP_IMGS[language].desktop.bannerRegisterBuyer,
          APP_IMGS[language].desktop.bannerRegisterSupplier,
        ]}
      />
      <Header />
      <Main sx={{ height: hideBanner ? "initial" : "calc(100vh - 73px)" }}>
        {!isMobile && !hideBanner && <LeftBanner />}
        <Suspense fallback={null}>{children}</Suspense>
      </Main>
    </Box>
  );
};

const Main = styled(Container)(({ theme }) => ({
  backgroundColor: "#fff",
  display: "flex",
  ".main": {
    width: "calc(50% + 48px)",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    position: "relative",
    alignItems: "center",
    ".scroll-box": {
      overflow: "hidden",
      overflowY: "scroll",
      width: "100%",
    },
    ".email-input": {
      input: {
        paddingLeft: 4,
      },
    },
    ".step-title": {
      color: "#333333",
    },
    ".step-desc": {
      color: "#666666",
    },
    "button.Mui-disabled": {
      backgroundColor: "#11111140",
      color: "#fff",
      "-webkit-text-fill-color": "#fff",
    },
    ".submit-btn": {
      borderRadius: 12,
    },
    ".link": {
      textDecoration: "underline",
    },
  },
  ".tmp": {
    height: 32,
  },
  [theme.breakpoints.down("md")]: {
    // height: "calc(100vh - 107px)",
    ".main": {
      width: "100%",
      justifyContent: "flex-start",
    },
    ".step-title": {
      fontSize: 20,
      // textAlign: "center",
    },
    ".step-desc": {
      fontSize: 14,
    },
  },
}));

export default AuthLayout;
