import { COMMON_DATA_ACTIONS } from "../actions/common-data";

const initialState = {
  unseenData: {},
  menuCountData: {},
  reloadMenuCountData: false,
};

const commonDataReducer = (
  state = initialState,
  action: { payload: any; type: string }
) => {
  switch (action.type) {
    case COMMON_DATA_ACTIONS.setUnseenData: {
      return {
        ...state,
        unseenData: action.payload,
      };
    }

    case COMMON_DATA_ACTIONS.setMenuCountData: {
      return {
        ...state,
        menuCountData: action.payload,
      };
    }

    case COMMON_DATA_ACTIONS.reloadMenuCountData: {
      return {
        ...state,
        reloadMenuCountData: !state.reloadMenuCountData,
      };
    }

    default:
      return state;
  }
};

export default commonDataReducer;
