const initialState = {
  reloadNoti: false,
  notiList: [],
  showNotiSnack: false,
  currentPage: 0
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NOTI_LIST": {
      return {
        ...state,
        notiList: action.payload,
      };
    }

    case "SET_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }

    case "RELOAD_NOTI": {
      return {
        ...state,
        reloadNoti: !state?.reloadNoti
      };
    }

    case "SHOW_NOTI_SNACK": {
      return {
        ...state,
        showNotiSnack: action.payload
      };
    }

    default:
      return state;
  }
};

export default notificationReducer;
