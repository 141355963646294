
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  popupBox :{
    position: "fixed",
    background: "#00000050",
    width: "100%",
    height: "100vh",
    top: 0,
    left: 0,
    zIndex: 5
  },
   
  box: {
    position: "absolute",
    bottom: "50%",
    left: "50%",
    transform: "translate(-50%, 50%)",
    margin: "0 auto",
    height: "auto",
    background: "#fff",
    borderRadius: "10px",
    padding: "10px",
    border: "1px solid #999",
    overflow: "auto",
    textAlign: "center",
  },
  button: {
    backgroundColor: "#E98100",
    color: "#fff",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: "#E98100",
    }
  },
  closeBox: {
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translate(-10%, 10%)"
  }
}))
