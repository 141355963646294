import { DIRECT_PATHS } from "constants/routes";
import useGetData from "hooks/useGetData";
import useNavigate from "hooks/useNavigate";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMenuCountData } from "redux/actions/common-data";
import { reloadMenuCountDataSelector } from "redux/selectors/common";
import { getUser } from "redux/selectors/user";
import { apiGetCountNotiMenuBar } from "services/api/buyer";
// import { useParams } from "react-router-dom";
import { BreadscrumbType } from "types/breadscrumb";

const CommonDataContext = createContext<{
  selectedCategory?: string;
  appConfigs?: any;
  setSelectedCategory: Dispatch<SetStateAction<string | undefined>>;
  onChangeSelectedCategory: (category: string, openNewTab?: boolean) => void;
  onChangeBreadscrumbs: (breadscumbs?: BreadscrumbType[]) => void;
  breadscumbs?: BreadscrumbType[];
}>({
  selectedCategory: undefined,
  setSelectedCategory: () => {},
  onChangeSelectedCategory: () => {},
  onChangeBreadscrumbs: () => {},
  breadscumbs: [],
});

export const CommonDataProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { history } = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUser) || {};

  const [selectedCategory, setSelectedCategory] = useState<
    string | undefined
  >();
  const [breadscumbs, setBreadscumbs] = useState<BreadscrumbType[]>([]);
  const reloadMenuCountData = useSelector(reloadMenuCountDataSelector);

  const { data: unSeenData } = useGetData({
    promise: () => (user.is_logged_in ? apiGetCountNotiMenuBar() : null),
    deps: [reloadMenuCountData, user.is_logged_in],
  });


  const onChangeSelectedCategory = (category: string, openNewTab?: boolean) => {
    setSelectedCategory(category);
    if (openNewTab) {
      window.open(DIRECT_PATHS.category(category));
    } else {
      history.push(DIRECT_PATHS.category(category));
    }
  };

  const onChangeBreadscrumbs = (breadscumbs?: BreadscrumbType[]) => {
    setBreadscumbs(breadscumbs || []);
  };

  useEffect(() => {
    if (unSeenData?.data) {
      dispatch(setMenuCountData(unSeenData?.data || {}));
    }
  }, [unSeenData, dispatch]);

  return (
    <CommonDataContext.Provider
      value={{
        selectedCategory,
        setSelectedCategory,
        onChangeSelectedCategory,
        onChangeBreadscrumbs,
        breadscumbs,
      }}
    >
      {children}
    </CommonDataContext.Provider>
  );
};

export const useCommonData = () => useContext(CommonDataContext);
