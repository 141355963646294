import { Stack, styled } from "@mui/material";
import {
  AcceptButton,
  ConfirmButton,
  Row,
  TextButton,
  TextSemibold,
} from "Components";
import { PATHS } from "constants/routes";
import useAuth from "hooks/useAuth";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { BlockType } from "types/common";

const Blocker = ({ is_blocked, required }: BlockType) => {
  const intl = useIntl();
  const { onDirectLogin, onDirectRegister } = useAuth();

  const onClickBecome = () => {
    window.location.href = PATHS.buyerPricing;
  };

  const blockTitle = useMemo(() => {
    return required === "login"
      ? "sign_in_sign_up_to_continue"
      : required === "premium"
      ? "buyers_blocker_desc"
      : required === "verified"
      ? "verified_to_all_results"
      : "";
  }, [required]);

  const BlockContent = useMemo(() => {
    switch (required) {
      case "login":
        return (
          <Row justifyContent="center">
            <AcceptButton onClick={onDirectRegister}>
              {intl.formatMessage({ id: "JoinForFree" })}
            </AcceptButton>
            <TextButton onClick={onDirectLogin}>
              {intl.formatMessage({ id: "login" })}
            </TextButton>
          </Row>
        );
      case "premium":
        return (
          <ConfirmButton onClick={onClickBecome}>
            {intl.formatMessage({ id: "become_premium_membership" })}
          </ConfirmButton>
        );

      case "verified":
        return (
          <AcceptButton onClick={onClickBecome}>
            {intl.formatMessage({ id: "verify_to_continue" })}
          </AcceptButton>
        );

      default:
        return null;
    }
  }, [required]);

  if (!is_blocked) {
    return null;
  }

  return (
    <StyledBlocker spacing={2} alignItems="center">
      {/*<Img src={RED_WARNING_ICON} alt="icon" className="warning-icon" />*/}
      <TextSemibold>
        {blockTitle &&
          intl.formatMessage({
            id: blockTitle,
          })}
      </TextSemibold>

      {BlockContent}
    </StyledBlocker>
  );
};

const StyledBlocker = styled(Stack)({
  textAlign: "center",
  paddingBottom: 32,
  borderRadius: 16,
  width: "100%",
  ".warning-icon": {
    width: 54,
    margin: "0 auto",
  },
  button: {
    maxWidth: 248,
  },
});

export default Blocker;
