import { Alert, AlertColor, Snackbar } from "@mui/material";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

type NotifyType = {
  type?: AlertColor;
  open: boolean;
  msg?: ReactNode;
};

const NotifyContext = createContext<{
  notify: NotifyType;
  setNotify: (notify: NotifyType) => void;
  onCloseNotify: any;
  onChangeNotify?: any;
  onShowNotify?: (msg?: string, type?: AlertColor) => void;
}>({
  notify: {
    open: false,
  },
  setNotify: () => null,
  onCloseNotify: () => null,
  onChangeNotify: () => null,
  onShowNotify: () => null,
});

export const useNotify = () => useContext(NotifyContext);

const NotifyProvider = ({ children }: { children: ReactNode }) => {
  const [notify, setNotify] = useState<NotifyType>({
    type: "success",
    open: false,
    msg: "This is a success message!",
  });

  const onCloseNotify = () => {
    setNotify({ ...notify, open: false });
  };

  const onChangeNotify = (updateData: any = {}) => {
    setNotify({ ...notify, ...updateData });
  };

  const onShowNotify = useCallback(
    (msg?: string, type: AlertColor = "success") => {
      setNotify({ open: true, msg, type });
    },
    []
  );

  const context = {
    notify,
    setNotify,
    onShowNotify,
    onCloseNotify,
    onChangeNotify,
  };

  return (
    <NotifyContext.Provider value={context}>
      {children}

      <Snackbar
        open={notify.open}
        onClose={onCloseNotify}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={onCloseNotify} severity={notify.type}>
          {notify.msg}
        </Alert>
      </Snackbar>
    </NotifyContext.Provider>
  );
};

export default NotifyProvider;
