import { FRESHDI_SUBDOMAIN, SEARCH_PARAMS } from "constants/routes";
import { decodeBase64 } from "helpers/base64";
import { LOCALSTORAGE_KEYS, settingStorage } from "helpers/localStorage";
import { isSameDomain } from "helpers/url";
import { useCallback, useMemo } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import useUser from "./useUser";
import { useLayout } from "providers/layout";

interface ParamTypes {
  id: string;
}

const useNavigate = () => {
  const { isLoggedIn } = useUser();
  const { search, pathname } = useLocation();
  const { onChangeSwitchAccountPopup } = useLayout();
  const location = pathname;
  const history = useHistory();
  const params = useParams<ParamTypes>();

  const useSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  const isMatchPath = (path: string) => {
    return window.location.pathname.indexOf(path) > -1;
  };

  const directKeepSearch = (path: string, s: string = "") =>
    history.push({
      pathname: path,
      search: search + (s ? `${search ? "&" : ""}${s}` : ""),
    });

  const isSubOrPridomain = useMemo(() => {
    let host = window.location.host;
    let hostPartis = host.split(".");
    return (
      (FRESHDI_SUBDOMAIN.indexOf(hostPartis[0]) < 0 &&
        hostPartis?.length > 1) ||
      (host?.indexOf("freshdi") < 0 && host?.indexOf("localhost") < 0)
    );
  }, [window.location.host]);

  const openNewTab = useCallback((url: string) => {
    window.open(url);
  }, []);

  const onDirectCheckSearchParam = (url: string) => {
    let directUrl = useSearchParams.get(SEARCH_PARAMS.directUrl);
    if (directUrl) {
      window.location.href = decodeBase64(directUrl);
    } else {
      history.push(url);
    }
  };

  const onShowSwitchAccount = (url: string) => {
    const passLocalStorageSwitch =
      settingStorage.get(LOCALSTORAGE_KEYS.showSwitchAccount) === 0;
    if (passLocalStorageSwitch || isSameDomain(url) || !isLoggedIn) {
      window.location.href = url;
    } else {
      onChangeSwitchAccountPopup({ open: true, data: { url } });
    }
  };

  return {
    pathname,
    search,
    history,
    location,
    useSearchParams,
    navigateSearch: (search: string) => history.push({ search }),
    isMatchPath,
    isSubOrPridomain,
    params,
    openNewTab,
    directKeepSearch,
    onDirectCheckSearchParam,
    onShowSwitchAccount,
  };
};

export default useNavigate;
