import { Avatar, Box, Button, Dialog, Menu, styled } from "@mui/material";

export const StyledAvatar = styled(Avatar)({
  width: 32,
  height: 32,
});

export const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": { width: "100%", maxWidth: 450 },
});

export const confirmBtnStyles = {
  mt: 2,
  "& .accept": {
    color: "#EB5757",
  },
};

export const StyledAuthButton = styled(Button)(({ hideUserName, theme }) => ({
  textTransform: "initial",
  padding: 0,
  "p, svg": {
    color: "var(--gray-500)",
  },
  p: {},
  ".status-label": {
    padding: "2px 8px",
  },
  ".MuiButton-startIcon": {
    marginRight: hideUserName ? 0 : 8,
  },
  minWidth: hideUserName ? "fit-content" : 64,
  [theme.breakpoints.down("md")]: {
    paddingLeft: 4,
  },
}));

export const StyledMenu = styled(Menu)({
  ".MuiPaper-root": {
    width: "100%",
    maxWidth: 284,
    borderRadius: 8,
  },
  p: {
    fontWeight: 500,
  },
  ".MuiList-root": {
    padding: 0,
  },
  ".MuiButtonBase-root": {
    borderRadius: 0,
    padding: "5px 16px",
  },
  ".badge-number": {
    marginLeft: 8,
  },
  ".user-info": {
    padding: "12px 15px",
    ".username": {
      maxWidth: 164,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    ".plan": {},
    ".avatar": {
      minWidth: 60,
      width: 60,
      height: 60,
      objectFit: "cover",
    },
    ".go-work-btn": {
      fontSize: 11,
      padding: 5,
      borderRadius: 8,
    },
  },
});

export const UpgradeMembershipBox = styled(Box)({
  ".discount_text": {
    padding: "2px 10px",
    borderRadius: 16,
    backgroundColor: "#FFF6ED",
    color: "#C4320A",
    marginRight: 16,
  },
  ".opportunity_text": {
    color: "#667085",
    fontWeight: 400,
  },
});

export const LogoutButton = styled(Button)({
  textTransform: "initial",
  display: "block",
  marginTop: 16,
  fontWeight: 500,
  color: "#344054",
});
