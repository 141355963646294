import { Tab, styled } from "@mui/material";

export const StyledTab = styled(Tab)(({ isSelected, isLastest }) => ({
  textTransform: "initial",
  fontSize: "14px",
  color: isSelected ? "#008934 !important" : "#828282",
  minWidth: "fit-content",
  padding: 0,
  marginRight: isLastest ? 0 : 16,
  minHeight: 40
}));
