
export const setCustomer = (customer) => {
  return {
    type: "SET_CUSTOMER",
    payload: customer
  }
}

export const changeCompany = (company) => {
  return {
    type: "CHANGE_COMPANY",
    payload: company
  }
}


export const setCustomerToken = (token) => {
  return {
    type: "SET_CUSTOMER_TOKEN",
    payload: token
  }
}


export const resetCustomer = () => {
  return {
    type: "RESET_CUSTOMER"
  }
}

export const setIsSubscribe = (data) => {
  return {
    type: "SET_IS_SUBSCRIBE",
    payload: data
  }
}
