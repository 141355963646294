import { Swiper as SwiperLib, SwiperProps } from "swiper/react";
import { Pagination } from "swiper";
import { useState } from "react";
import { Box, IconButton, styled } from "@mui/material";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import SwiperCore, { Autoplay } from "swiper";

// import {
//   ArrowBackIosOutlined,
//   ArrowForwardIosOutlined,
// } from "@mui/icons-material";

SwiperCore.use([Autoplay]);

const Swiper = (props: SwiperProps & { has_panigation?: boolean }) => {
  const {
    has_panigation,
    breakpoints = {
      300: {
        slidesPerView: 2.5,
        spaceBetween: 8,
      },
      640: {
        slidesPerView: 5,
        spaceBetween: 8,
      },
      1440: {
        slidesPerView: 6,
        spaceBetween: 16,
      },
    },
    ...otherProps
  } = props;
  const [swiperRef, setSwiperRef] = useState<any>();

  const onClickNext = () => {
    swiperRef?.slideNext();
  };

  const onClickBack = () => {
    swiperRef?.slidePrev();
  };

  return (
    <Box position="relative">
      <StyledSwiperLib
        ref={swiperRef}
        breakpoints={breakpoints}
        onInit={(ev) => {
          setSwiperRef(ev);
        }}
        className="mySwiper"
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        {...otherProps}
      >
        {props.children}
      </StyledSwiperLib>
      {has_panigation && (
        <>
          <StyledButton className="previous" onClick={onClickBack}>
            <IoIosArrowBack />
          </StyledButton>

          <StyledButton className="next" onClick={onClickNext}>
            <IoIosArrowForward />
          </StyledButton>
        </>
      )}
    </Box>
  );
};

const StyledSwiperLib = styled(SwiperLib)(({ theme }) => ({
  // paddingBottom: 16,
  "& .swiper-pagination": {
    // bottom: 0,
  },
  "& .swiper-pagination-bullet-active": {
    backgroundColor: "#ddd",
  },
  [theme.breakpoints.down("md")]: {
    "& .swiper-pagination": {
      bottom: 0,
    },
  },
}));

const StyledButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "calc(50% - 32px)",
  transform: "translateY(-50%)",
  zIndex: 5,
  "&,:hover": {
    backgroundColor: "#fff",
  },
  boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
  "& svg": {
    fontSize: 20,
  },
  "&.previous": {
    left: 8,
  },
  "&.next": {
    right: 8,
  },
  [theme.breakpoints.down("md")]: {
    "&.previous": {
      left: -8,
    },
    "&.next": {
      right: -8,
    },
  },
}));

export default Swiper;
