import { combineReducers } from "redux";
import cartReducer from "./cart";
import qrcartReducer from "./qrcart";
import itemReducer from "./item";
import userReducer from "./user";
import popupReducer from "./popup";
import commonReducer from "./common";
import orderReducer from "./order";
import voucherReducer from "./voucher";
import batchReducer from "./batch";
import addressReducer from "./address";
import layoutReducer from "./layout";
import notificationReducer from "./notification";
import commonDataReducer from "redux/reducers/common-data";
import chatReducer from "redux/reducers/chat";

export default combineReducers({
  cartReducer,
  itemReducer,
  userReducer,
  popupReducer,
  commonReducer,
  qrcartReducer,
  orderReducer,
  voucherReducer,
  batchReducer,
  addressReducer,
  layoutReducer,
  notificationReducer,
  commonDataReducer,
  chatReducer,
});
