import { TOKEN } from "constants/schemas";

export const getConfigs = () => {
  let checkProduct = process.env.REACT_APP_ENV === "production";
  let configs = {
    WEB_DOMAIN: checkProduct ? "https://freshdi.com" : "https://tr.freshdi.com",
    BIZ_DOMAIN: checkProduct
      ? "https://business.freshdi.com"
      : "https://biz.freshdi.com",
    SERVER_URL: `https://${
      checkProduct ? "svc" : "rpc"
    }.freshdi.com/api/method/`,
    BASE_URL: `https://${checkProduct ? "svc" : "rpc"}.freshdi.com`,
    CHAIN_SERVER_URL: `https://${
      checkProduct ? "blockchain" : "block"
    }.freshdi.com`,
    WEBSOCKET_SERVER_URL: `wss://${
      checkProduct ? "" : "tr."
    }freshdi.com/wss/wss_order_app`,
    IMG_SERVER_URL: `https://${checkProduct ? "cdn-media" : "pan"}.freshdi.com`,
    TOKEN: `token ${TOKEN}`,
    GM_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API,
  };

  return configs;
};
