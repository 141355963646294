import { Grid, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const StyledFooter = styled(Grid)({
  ".footer_main": {
    width: "100%",
  },
  p: {
    color: "var(--gray-500)",
  },
  ".footer_title": {
    color: "#000000",
  },
  ".footer_title, .footer_link p": {
    fontWeight: 400,
    fontSize: 14,
  },
  ".footer_link": {
    textDecorationColor: "var(--gray-500)",
  },
});

export default makeStyles((theme) => ({
  footer: {},
  gridInfo: {
    maxWidth: "1024px",
  },
  icon: {
    fontSize: "1.25rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
    },
  },
  link: {
    "& a": {
      textDecoration: "initial",
      color: "#000",
    },
  },
  linkText: {
    padding: 8,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#DDF4E9",
      borderRadius: 8,
      color: "#008834 !important",
    },
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft: 0
    // }
  },
  downBtn: {
    height: 45,
    maxWidth: 115,
  },
  downBtnBox: {
    marginLeft: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export const StyledLastFooter = styled(Grid)(({ theme }) => ({
  "& .forFarmText": {
    "& span": {
      marginLeft: 4,
      color: "var(--green-text-color)",
    },
    fontSize: "12px",
  },
  "& .padding": "24px 0",
  "& p": {
    color: "#8C8C8C",
  },
  [theme.breakpoints.down("md")]: {
    "& p": {},
  },
}));
