import { Dialog } from "@mui/material";
import { LoadingSkeleton, MailButton, SwitchAccountPopup } from "Components";
import {
  FC,
  ReactNode,
  Suspense,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { SwitchAccountPopupType } from "types/layout";

const LayoutContext = createContext<{
  showFullMenu: boolean;
  onToggleShowFull: () => void;
  switchAccountPopup: SwitchAccountPopupType;
  onChangeSwitchAccountPopup: (data?: SwitchAccountPopupType) => void;
}>({
  showFullMenu: false,
  onToggleShowFull: () => {},
  switchAccountPopup: { open: false },
  onChangeSwitchAccountPopup: () => {},
});

export const LayoutProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [showFullMenu, setShowFullMenu] = useState(false);
  const [switchAccountPopup, setSwitchAccountPopup] =
    useState<SwitchAccountPopupType>({
      open: false,
    });

  const onToggleShowFull = useCallback(() => {
    setShowFullMenu(!showFullMenu);
  }, [showFullMenu]);

  const onChangeSwitchAccountPopup = (data?: SwitchAccountPopupType) =>
    setSwitchAccountPopup({ ...switchAccountPopup, ...data });

  const onCloseSwitchAccountPopup = () =>
    setSwitchAccountPopup({ open: false });

  const contextData = {
    showFullMenu,
    onToggleShowFull,
    switchAccountPopup,
    onChangeSwitchAccountPopup,
  };

  return (
    <LayoutContext.Provider value={contextData}>
      {children}

      <Dialog
        open={switchAccountPopup.open}
        onClose={onCloseSwitchAccountPopup}
      >
        <Suspense fallback={<LoadingSkeleton count={3} m={2} />}>
          <SwitchAccountPopup
            onClose={onCloseSwitchAccountPopup}
            data={switchAccountPopup.data}
          />
        </Suspense>
      </Dialog>

      <MailButton />
    </LayoutContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutContext);
