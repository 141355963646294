import { SVGProps } from "react";

export function MdShieldIcon({
  width = 50,
  height = 50,
  viewBox = "0 0 50 50",
  stroke = "#019C3C",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M16.666 20.8333L24.9993 29.1667L33.3327 20.8333M8.33268 6.25H41.666C42.7711 6.25 43.8309 6.68899 44.6123 7.47039C45.3937 8.25179 45.8327 9.3116 45.8327 10.4167V22.9167C45.8327 28.442 43.6377 33.741 39.7307 37.6481C35.8237 41.5551 30.5247 43.75 24.9993 43.75C22.2635 43.75 19.5544 43.2111 17.0268 42.1642C14.4992 41.1172 12.2025 39.5826 10.268 37.6481C6.36095 33.741 4.16602 28.442 4.16602 22.9167V10.4167C4.16602 9.3116 4.605 8.25179 5.3864 7.47039C6.16781 6.68899 7.22761 6.25 8.33268 6.25Z"
        stroke={stroke}
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
