import { withLoading } from "hocs/withLoading";
import { lazy } from "react";

export { default as LogoFreshdi } from "./LogoFreshdi";
export { default as Title } from "./Title";
export { default as Popup } from "./Popup/Popup";
export { default as Wait } from "./Wait";
export { default as CustomModal } from "./CustomModal";
export { default as CustomSkeleton } from "./CustomSkeleton";
export { default as CustomDialog } from "./CustomDialog";
export { default as LineBreak } from "./LineBreak";
export { default as ScrollTopButton } from "./ScrollTopButton";
export { default as DynamicMedia } from "./DynamicMedia";
export { default as DynamicThumbnail } from "./DynamicThumbnail";
export { default as ConfirmBtns } from "./ConfirmBtns";
export { default as SelectLanguage } from "./SelectLanguage";
export { default as AuthBar } from "./AuthBar";
export { default as StyledTabs } from "./StyledTabs";
export { default as LoadedImage } from "./LoadedImage";
export { default as FormattedMessage } from "./FormattedMessage";
export { default as CustomBreadcrumb } from "./CustomBreadcrumb";
export { default as Footer } from "./Footer";
export { default as Header } from "./Header";
export * from "./Img";
export { default as Main } from "./Main";
export { default as Product } from "./Product";
export { default as Company } from "./Company";
export { default as Swiper } from "./Swiper";
export { default as Pagnigation } from "./Pagnigation";
export { default as Layout } from "./Layout";
export { default as LoadingSkeleton } from "./LoadingSkeleton";
export { default as IntlMsg } from "./IntlMsg";
export { default as TextOnLine } from "./TextOnLine";
export * from "./Button";
export * from "./CircularLoading";
export * from "./ErrorText";
export * from "./SnackbarNotify";
export { default as Row } from "./Row";
export { default as Menu } from "./Menu";
export { default as Tabs } from "./Tabs";
export { default as HtmlViewer } from "./HtmlViewer";
export * from "./Input";
export { default as RFQStatus } from "./RFQStatus";
export { default as TimeDiff } from "./TimeDiff";
export { default as StyledRFQHeader } from "./StyledRFQHeader";
export { default as HeaderServices } from "./HeaderServices";
export { default as ItemList } from "./ItemList";
export { default as ItemPrices } from "./ItemPrices";
export { default as Attachment } from "./Attachment";
export { default as CountryFlag } from "./CountryFlag";
export { default as Breadcrumbs } from "./Breadcrumbs";
export { default as SuggestProduct } from "./SuggestProduct";
export { default as GetBusinessCard } from "./GetBusinessCard";
export const Categories = withLoading(lazy(() => import("./Categories")));
export { default as RoundInputWithLabel } from "./RoundInputWithLabel";
export { default as Blocker } from "./Blocker";
export { default as Buyer } from "./Buyer";
export { default as UploadFiles } from "./UploadFiles";
export * from "./Text";
export const ChipList = withLoading(lazy(() => import("./ChipList"), 0));
export { default as Disclaimer } from "./Disclaimer";
export const RFQ = lazy(() => import("./RFQ"));
export { default as OTPInput } from "./OTPInput";
export * from "./Tooltip";
export { default as Post } from "./Post";
export const RelatedSearchesComponent = lazy(() => import("./RelatedSearches"));
export const RelatedSearches = withLoading(RelatedSearchesComponent);
export const Quotation = lazy(() => import("./Quotation"));
export const Notification = lazy(() => import("./Notification"));
export const HeaderChat = lazy(() => import("./HeaderChat"));
export { default as Popover } from "./Popover";
export const ChatList = lazy(() => import("./ChatList"));
export const Chat = lazy(() => import("./Chat"));
export const MobileChatDetail = lazy(() => import("./MobileChatDetail"));
export { default as SimpleHeader } from "./SimpleHeader";
export const SwitchAccountPopup = lazy(() => import("./SwitchAccountPopup"));
export const ReportForm = withLoading(
  lazy(() => import("./ReportForm")),
  2
);
export * from "./Checkbox";
export * from "./Autocomplete";
export * from "./TipBanners";
export * from "./AnimationText";
export const SendInquiry = withLoading(
  lazy(() => import("./SendInquiry")),
  0
);
export { default as AIGenerateInput } from "./input/AIGenerateInput";
