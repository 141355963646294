import { styled, Checkbox as CheckboxLib } from "@mui/material";
import { COLORS } from "constants/layout";

export const Checkbox = styled(CheckboxLib)({
  padding: 0,
  // color: COLORS.green[50],
  // "&.Mui-checked": {
  //   color: COLORS.green[50],
  // },
  svg: {
    fontSize: 20,
  },
});
