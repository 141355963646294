const initialState = {
  orderDetail: null,
  refreshOrder: false,
  orderBarChildren: [],
  ordersData: {},
};

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REPLACE_ORDER":
      return {
        ...state,
        orderDetail: action.payload,
      };

    case "SET_ORDER_BAR_CHILDERN":
      return {
        ...state,
        orderBarChildren: [
          ...state?.orderBarChildren,
          ...(state?.orderBarChildren?.indexOf(action.payload) > -1
            ? []
            : [action.payload]),
        ],
      };

    case "REFRESH_ORDER":
      return {
        ...state,
        refreshOrder: !state?.refreshOrder,
      };

    case "SET_ORDERS_DATA":
      return {
        ...state,
        ordersData: action.payload,
      };

    default:
      return state;
  }
};

export default OrderReducer;
