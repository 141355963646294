function createStorage(key: string) {
  const store = JSON.parse(window.localStorage.getItem(key) || "{}");
  const save = () => {
    window.localStorage.setItem(key, JSON.stringify(store));
  };
  const storage = {
    get(key: string) {
      return store[key];
    },
    set(key: string, value: any) {
      store[key] = value;
      save();
    },
    remove(key: string) {
      delete store[key];
      save();
    },
  };
  return storage;
}

export const authStorage = createStorage("auth");
export const routes = createStorage("routes");
export const langStorage = createStorage("lang");
export const chatStorage = createStorage("chat");
export const settingStorage = createStorage("setting");

export const LOCALSTORAGE_KEYS = {
  twlk: "06cf6fc3-9fa9-41a1-ad13-6ab4a3225913",
  twlid: "18c8709d-afe6-4585-9c21-bb942cb456eb",
  showSwitchAccount: "299510b8-ad5a-4345-b957-00bc1bd694c3",
};
