const initialState = {
  reloadAddresses: false,
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RELOAD_ADDRESSES": {
      return {
        ...state,
        reloadAddresses: !state.reloadAddresses,
      };
    }

    default:
      return state;
  }
};

export default addressReducer;
