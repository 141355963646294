import { keyframes, styled, Typography, TypographyProps } from "@mui/material";

const scrollText = keyframes`
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

type MarquueTextProps = TypographyProps & { timer?: number };

const MarqueeText = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  paddingLeft: "100%",
  animation: `${scrollText} 10s linear infinite`,
}));

export const RunningText = (props: MarquueTextProps) => {
  return (
    <MarqueeContainer>
      <MarqueeText {...props} />
    </MarqueeContainer>
  );
};

const MarqueeContainer = styled("div")({
  overflow: "hidden",
  whiteSpace: "nowrap",
  display: "flex",
});
