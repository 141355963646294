import React, { useEffect, useState } from "react";
import useStyles from "./styles";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { IconButton } from "@mui/material";

const ScrollTopButton = (props) => {
  const classes = useStyles();
  const { att } = props;

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <IconButton
      className={classes.srollTopBtn}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      sx={{
        display: scrollTop > 500 ? "initial" : "none",
        ...att
      }}
    >
      <ArrowUpwardIcon />
    </IconButton>
  );
};

export default ScrollTopButton;
