import { Skeleton, Stack, StackProps, styled } from "@mui/material";

const LoadingSkeleton = (props: StackProps & { count?: number }) => {
  const { count = 3 } = props;

  return (
    <StyledStack spacing={1} alignItems="center" width="100%" {...props}>
      {[...Array(count)].map((count, i) => (
        <Skeleton className="loading_box" key={i} />
      ))}
    </StyledStack>
  );
};

export const ProductSkeleton = () => {
  return (
    <StyledProductSkeleton>
      <Skeleton variant="rounded" height={195} />
      <Stack p={1} spacing={1}>
        <Skeleton variant="text" height={20} width={148} />
        <Skeleton variant="text" height={20} width={60} />
        <Skeleton variant="text" height={20} width={96} />
        <Skeleton variant="text" height={20} width={148} />
        <Skeleton variant="rounded" height={40} width="100%" />
      </Stack>
    </StyledProductSkeleton>
  );
};

const StyledProductSkeleton = styled(Stack)({
  height: 320,
  backgroundColor: "#fff",
  borderRadius: 8,
  border: "1px solid #eaecf0",
});

const StyledStack = styled(Stack)({
  "& .loading_box": {
    height: 24,
    width: 164,
  },
});

export default LoadingSkeleton;
