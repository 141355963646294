import { Typography, TypographyProps, styled } from "@mui/material";

const ErrorText = (props: TypographyProps) => {
  return <StyledTypography textAlign="center" {...props} />;
};

const StyledTypography = styled(Typography)({
  color: "var(--text-error-color)",
  fontSize: "0.75rem",
  margin: "0 14px",
});

export { ErrorText };
