import React, { useCallback, useEffect } from "react";

import { authStorage } from "helpers/localStorage";
import { apiGetMe } from "services/api/user";
import { useDispatch } from "react-redux";
import { setUser } from "redux/actions/user";
import useNavigate from "hooks/useNavigate";
import { PATHS, SEARCH_PARAMS } from "constants/routes";
import { encodeBase64 } from "helpers/base64";
import ReactGA from "react-ga4";

export const withProfile =
  (WrappedComponent: React.FC<any>) => (props: any) => {
    const auth = authStorage;
    const token = auth.get("token");
    const dispatch = useDispatch();
    const { isMatchPath, location, history } = useNavigate();

    const directLogin = () => {
      history.push(
        `${PATHS.login}?${SEARCH_PARAMS.directUrl}=${encodeBase64(location)}`
      );
    };

    const fetchData = useCallback(async () => {
      await apiGetMe(token)
        .then((res) => {
          let newUserData = res?.data?.message?.data || {};
          newUserData["freshdi_customer_id"] = newUserData?.name;
          dispatch(setUser(newUserData));
          if (!newUserData?.is_logged_in) {
            ReactGA.set({ userId: null })
            if (isMatchPath("user")) {
              directLogin();
            }
          }
          else {
            ReactGA.set({ userId: newUserData?.uuid })
          }
        })
        .catch((err) => {
          console.log(err);
          auth.remove("token");
        });
    }, [dispatch, token]);

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    return <WrappedComponent {...props} />;
  };
