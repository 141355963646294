import { SVGProps } from "react";

export function EmailIcon({
  width = 21,
  height = 21,
  viewBox = "0 0 21 21",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M18.8333 5.22624C18.8333 4.30957 18.0833 3.55957 17.1667 3.55957H3.83332C2.91666 3.55957 2.16666 4.30957 2.16666 5.22624M18.8333 5.22624V15.2262C18.8333 16.1429 18.0833 16.8929 17.1667 16.8929H3.83332C2.91666 16.8929 2.16666 16.1429 2.16666 15.2262V5.22624M18.8333 5.22624L10.5 11.0596L2.16666 5.22624"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
