import { CircularLoading } from "Components";
import { FC, ReactNode, createContext, useContext, useState } from "react";

const LoadingContext = createContext<{
  loading: boolean;
  setLoading: any;
}>({
  loading: false,
  setLoading: () => null,
});

export const LoadingProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}

      <CircularLoading open={loading} onClose={() => setLoading(false)} />
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
