import { styled } from "@mui/material";
import OTPInputLib, { OTPInputProps } from "react-otp-input";

const OTPInput = (props: OTPInputProps) => {
  return (
    <StyledOTPInputLib>
      <OTPInputLib
        inputType="number"
        numInputs={6}
        renderSeparator={null}
        containerStyle="otp-box"
        inputStyle="otp-input"
        {...props}
        renderInput={(props) => <input {...props} />}
      />
    </StyledOTPInputLib>
  );
};

const StyledOTPInputLib = styled("div")(({ theme }) => ({
  ".otp-box": {
    gap: 8,
  },
  ".otp-input": {
    flex: 1,
    borderRadius: 8,
    height: 80,
    marginRight: 0,
    border: "0.5px solid #BDBDBD",
    fontSize: 48,
    color: "var(--sub-color)",
  },
  [theme.breakpoints.down("md")]: {
    ".otp-input": {
      borderRadius: 4,
      height: 48,
      fontSize: 28,
    },
  },
}));

export default OTPInput;
