import http from "services/http";

const PRE_ENDPOINT = "/api/method/freshdi.data.web.order.user";
const V3_ENDPOINT = "/api/method/freshdi.api.web.v3";

export const apiCreateAnonymous = () =>
  http.post(`${PRE_ENDPOINT}.create_anonymous`);

export const apiGetMe = (token?: string) =>
  http.get(`${V3_ENDPOINT}.customer.me`, { params: { token } });

export const apiGetSuppliertMe = (token?: string) =>
  http.get(`${V3_ENDPOINT}.supplier.me`, { params: { token } });

export const apiGetUserInformation = (token?: string) =>
  http.get(`${V3_ENDPOINT}.customer.get_profile`, { params: { token } });

export const apiLoginViaPw = (data: any) =>
  http.post(`${V3_ENDPOINT}.auth.login_via_password`, data);

export const apiRegister = (data: any) =>
  http.post(`${V3_ENDPOINT}.auth.signup`, data);

export const apiRegisterWithSocial = (data: any) =>
  http.post(`${V3_ENDPOINT}.auth.create_account`, data);

export const apiResetPassword = (data: any) =>
  http.post(`${V3_ENDPOINT}.auth.reset_password`, data);

export const apiUpdatePassword = (data: any) =>
  http.post(`${V3_ENDPOINT}.auth.update_password`, data);

export const apiActiveAccount = (data: any) =>
  http.post(`${V3_ENDPOINT}.auth.activate_account`, data);

export const apiUnsubscribe = (data: any) =>
  http.post(`${V3_ENDPOINT}.unsubscribe`, data);

export const apiRegisterWithGg = (data: any) =>
  http.post(`${V3_ENDPOINT}.auth.login_via_google`, data);

export const apiCheckUserExist = (data: any) =>
  http.post(`${V3_ENDPOINT}.customer.check_phone_exist`, data);

export const apiUpdateUser = (data: any) =>
  http.post(`${V3_ENDPOINT}.customer.update_profile`, data);

export const apiCheckAccountExists = (data: any) =>
  http.post(`${V3_ENDPOINT}.check_account_exist`, data);

export const apiLogoutUser = () => http.get(`${V3_ENDPOINT}.customer.logout`);

export const apiRequestVetification = (data: {
  email: string;
  doc_file: string;
}) => http.post(`${V3_ENDPOINT}.customer.request_verification`, data);

export const apiGetQuotationDetails = (quotation_id: string) =>
  http.get(`${V3_ENDPOINT}.customer.get_quotation_details`, {
    params: { quotation_id },
  });
