import { Link, styled } from "@mui/material";
import logo from "../../Assets/icons/logo/logo.svg";
import shortLogo from "../../Assets/icons/logo/short_logo.svg";

export default function LogoFreshdi({ isShort = false }) {
  return (
    <StyledLink underline="none" href="\">
      <img className="logo" src={isShort ? shortLogo : logo} alt="logo" />
    </StyledLink>
  );
}

const StyledLink = styled(Link)({
  ".logo": {
    height: 48,
  },
  p: {
    fontSize: 8,
    color: "#000",
  },
});
