import { styled } from "@mui/material";
import { APP_IMGS } from "Assets/images";
import { useLanguageContext } from "Components/LanguageProvider";
import React from "react";

const LeftBanner = () => {
  const { language } = useLanguageContext();
  return <StyledDiv src={APP_IMGS[language].desktop.bannerRegisterSupplier} />;
};

const StyledDiv = styled("img")({
  width: "calc(50% - 48px)",
  aspectRatio: "1.083 / 1",
  objectFit: "contain",
});

export default LeftBanner;
