const saveCart = (carts = []) => {
  localStorage.setItem("carts", JSON.stringify(carts));
};

const initialState = {
  numberCart: 0,
  carts: [],
  products: [],
  showCart: false,
  selectedItem: null,
};

const cartsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PUSH_VER2": {
      let newCarts = [];
      action.payload?.map((attribute) => {
        if (attribute?.quantity > 0) {
          let findIndex = state?.carts?.findIndex(
            (item) =>
              item?.name === attribute?.name && item?.note === attribute?.note
          );
          if (findIndex >= 0) {
            let indexItem = state.carts[findIndex];
            state.carts[findIndex] = {
              ...indexItem,
              quantity: indexItem?.quantity + attribute?.quantity,
            };
          } else {
            newCarts.push(attribute);
          }
        }
        return attribute;
      });

      saveCart([...state.carts, ...newCarts]);
      return {
        ...state,
        carts: [...state.carts, ...newCarts],
      };
    }

    case "ADD_ITEM": {
      let item_exists = false;
      let newCarts = state.carts.map((item) => {
        if (item.name === action.payload?.name) {
          item_exists = true;
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }
        return item;
      });
      if (!item_exists) {
        newCarts.push({
          ...action.payload,
          quantity: action.payload?.quantity || 1,
        });
      }

      saveCart(newCarts);

      return {
        ...state,
        carts: newCarts,
      };
    }

    case "UPDATE_SAME_ITEM":
      let newCarts = state?.carts
        ?.filter((item) => item?.item_id !== action.payload.item_id)
        ?.concat(action.payload?.items || []);
      return {
        ...state,
        carts: newCarts,
      };

    case "REPLACE_CART_VER2":
      return {
        ...state,
        carts: action.payload,
      };

    case "UPDATE": {
      let itemExists = false;
      let new_qlt = action.payload.attributes.filter(
        (attribute) => attribute?.quantity > 0
      ).length;
      // update attributes when item exists
      state.carts = state.carts.map((item) => {
        if (item?.name === action.payload.name) {
          let old_qlt = item.attributes.filter(
            (attribute) => attribute?.quantity > 0
          ).length;
          state.numberCart = state.numberCart - old_qlt + new_qlt;
          itemExists = true;
          return { ...item, attributes: action.payload.attributes };
        }
        return item;
      });

      if (!itemExists) {
        state = {
          ...state,
          carts: [...state.carts, action.payload],
          numberCart: state.numberCart + new_qlt,
        };
      }

      return {
        ...state,
      };
    }

    case "SET_SHOW_CART":
      return {
        ...state,
        showCart: action.payload,
      };

    case "RESET_CART":
      return {
        ...state,
        carts: [],
        numberCart: 0,
      };

    case "REMOVE_ITEM_VER2": {
      let newCarts = state.carts.filter(
        (item) =>
          item?.name !== action.payload?.name ||
          item?.note !== action.payload.note ||
          JSON.stringify(item?.toppings) !==
            JSON.stringify(action.payload?.toppings)
      );

      saveCart(newCarts);
      return {
        ...state,
        carts: newCarts,
      };
    }

    case "SET_SELECTED_ITEM":
      return {
        ...state,
        selectedItem: action.payload,
      };

    case "DESC_QTY_VER2": {
      let newCarts = state.carts;
      const { name, note, toppings, item_id } = action.payload;

      // Kiểm tra số lượng sp đang muốn xóa trong giỏ hàng
      let sameItemInCartQuantity = state.carts.filter(
        (i) => i?.item_id === item_id
      )?.length;

      newCarts = newCarts
        .map((item) => {
          let condition =
            sameItemInCartQuantity > 1
              ? item?.name === name &&
                (item?.note || "") === (note || "") &&
                JSON.stringify(item?.toppings) === JSON.stringify(toppings)
              : item?.name === name || (!name && item?.item_id === item_id);

          if (condition) {
            return { ...item, quantity: item.quantity - 1 };
          }
          return item;
        })
        .filter((item) => item.quantity > 0);

      saveCart(newCarts);

      return {
        ...state,
        carts: newCarts,
        numberCart: state?.numberCart - 1,
      };
    }

    case "INSC_QTY_VER2": {
      // Check and update item
      let item_exists = false;
      let newCarts = state.carts.map((item) => {
        if (
          item.name === action.payload?.name &&
          item?.note === action.payload?.note &&
          JSON.stringify(item?.toppings) ===
            JSON.stringify(action.payload?.toppings)
        ) {
          item_exists = true;
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }
        return item;
      });
      if (!item_exists) {
        newCarts.push({
          ...action.payload,
          quantity: action.payload?.quantity || 1,
          toppings: action.payload?.toppings || [],
        });
      }

      saveCart(newCarts);

      return {
        ...state,
        carts: newCarts,
        numberCart: state?.numberCart + 1,
      };
    }

    case "CHANGE_NOTE_VER_2": {
      // Check and update item
      let newCarts = state.carts.map((item) => {
        if (
          item.name === action.payload?.item?.name &&
          JSON.stringify(item?.toppings) ===
            JSON.stringify(action.payload?.item?.toppings)
        ) {
          return {
            ...item,
            note: action.payload.note,
          };
        }
        return item;
      });
      saveCart(newCarts);

      return {
        ...state,
        carts: newCarts,
      };
    }

    case "SET_QTY": {
      const item = state.carts.find(
        (item) => item.name === action.payload.item.name
      );
      if (item) {
        if (action.payload.quantity > 0) {
          return {
            ...state,
            carts: state.carts.map((item) =>
              item.name === action.payload.item.name
                ? { ...item, qty: action.payload.quantity }
                : item
            ),
          };
        }
        return {
          ...state,
          carts: state.carts.filter(
            (item) => item.name !== action.payload.item.name
          ),
        };
      }
      if (action.payload.quantity > 0) {
        return {
          ...state,
          carts: [
            ...state.carts,
            { ...action.payload.item, qty: action.payload.quantity },
          ],
        };
      }
      return {
        ...state,
      };
    }

    case "SELECT_ITEM":
      const item = state.carts.find(
        (item) => item.name === action.payload.name
      );
      if (item) {
        return {
          ...state,
          selectedItem: { ...action.payload, qty: item.qty },
        };
      }
      return {
        ...state,
        selectedItem: { ...action.payload, qty: 0 },
      };

    case "CHANGE_POINT":
      return {
        ...state,
        salePoint: action.payload,
      };

    default:
      return state;
  }
};

export default cartsReducer;
