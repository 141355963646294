
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  
  srollTopBtn: {
    height: 44,
    width: 44,
    transition: 'all 1s ease',
    backgroundColor: '#fff !important',
    border: '1px solid #e0e0e0 !important',
    fontSize: '15px !important'
  },
  lastestFooter: {
    fontSize: '14px !important'
  }
}))