import { Breadcrumbs, Link, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export default function CustomBreadcrumb({ links = [] }) {
  const intl = useIntl();

  const paths = useMemo(
    () => [{ label: intl.formatMessage({ id: "home" }), value: "/" }, ...links],
    [links]
  );

  return (
    <Stack flexDirection="col" alignItems="left">
      <CustomBreadcrumbs aria-label="breadcrumb">
        {paths?.map((link, index) => (
          <CustomLink variant="body2" href={`${link?.value}`} key={index}>
            {link?.label}
          </CustomLink>
        ))}
      </CustomBreadcrumbs>
    </Stack>
  );
}

const CustomLink = styled(Link)({
  textDecorationLine: "initial",
  color: "#00000099",
  display: "block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const CustomBreadcrumbs = styled(Breadcrumbs)({
  "& .MuiBreadcrumbs-separator": {
    margin: "0 4px",
  },
  ol: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    flexWrap: "nowrap",
    "li:last-child": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
});
