import { SVGProps } from "react";

export function FilterIcon({
  width = 18,
  height = 20,
  viewBox = "0 0 18 20",
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M16.5 2.95654H1.5L7.5 10.36V15.4783L10.5 17.0435V10.36L16.5 2.95654Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
