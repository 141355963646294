import { SET_GENERATING_AI } from "redux/actions/actionTypes";

const initialState = {
  generatingAI: false,
};

const layoutReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case SET_GENERATING_AI:
      return {
        ...state,
        generatingAI: action?.payload,
      };

    default:
      return state;
  }
};

export default layoutReducer;
