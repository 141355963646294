import { Popover, PopoverProps } from "@mui/material";

const AppPopover = (props: PopoverProps) => (
  <Popover
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
);
export default AppPopover;
