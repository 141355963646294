import { DependencyList, useEffect, useMemo, useState } from "react";

const useGetData = ({
  promise,
  deps = [],
}: {
  promise: any;
  deps?: DependencyList;
}) => {
  const [isLoading, setisLoading] = useState(true);
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();

  const fetchData = async () => {
    try {
      let data = (await promise()) as any;
      setData(data?.data?.message);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    setisLoading(true);
    fetchData();
  }, [...deps]);

  return useMemo(
    () => ({
      isLoading,
      data,
      error,
    }),
    [isLoading, data, error]
  );
};

export default useGetData;
