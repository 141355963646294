import { SUPPLIER_PLANS } from "constants/data";
import { useMemo } from "react";
import { Img } from "./Img";
import { Tooltip } from "./Tooltip";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";

export const PlanChip = ({
  plan,
  height = 18,
}: {
  plan?: string;
  height?: number;
}) => {
  const t = useIntl();
  const currentPlan = useMemo(
    () => SUPPLIER_PLANS.find((p) => p.label === plan),
    [plan]
  );

  if (!currentPlan?.label2) return null;

  return (
    <Tooltip title={t.formatMessage({ id: "plan_chip_tooltip" })}>
      <Box display="flex">
        <Img
          src={currentPlan.planIcon}
          alt="icon"
          className="plan-chip"
          height={height}
        />
      </Box>
    </Tooltip>
  );
};
