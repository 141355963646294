import { Box, BoxProps, styled } from "@mui/material";
import { IMG_DOMAIN } from "constants/schemas";
import { useEffect, useState } from "react";

const HtmlViewer = ({
  content = "",
  ...otherProps
}: { content: string } & BoxProps) => {
  const [updatedHtml, setUpdateHtml] = useState("");

  useEffect(() => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    const imgElements = tempDiv.querySelectorAll("img");

    imgElements.forEach((img) => {
      const currentSrc = img.getAttribute("src");
      if (
        currentSrc &&
        !currentSrc.startsWith("http") &&
        !currentSrc.startsWith("data:image/")
      ) {
        img.setAttribute("src", `${IMG_DOMAIN}${currentSrc}`);
      }
    });

    setUpdateHtml(tempDiv.innerHTML);
  }, [content, IMG_DOMAIN]);

  return (
    <StyledBox
      dangerouslySetInnerHTML={{ __html: updatedHtml }}
      {...otherProps}
    />
  );
};

const StyledBox = styled(Box)({
  fontFamily: "Inter",
  lineHeight: "1.43",
  overflowWrap: "anywhere",
  whiteSpace: "pre-wrap",
  "& p": {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    "&:has(img)": {
      textAlign: "center",
    },
    "p:has(a)": {
      textAlgin: "center",
    },
  },
  img: {
    maxWidth: "100%",
    maxHeight: 480,
    margin: "0 auto",
  },
  "*": {
    maxWidth: "100%",
    float: "initial",
  },
  "table, th, td": {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  table: {
    margin: "0 auto",
  },
  ".ql-code-block": {
    whiteSpace: "wrap",
  },
});

export default HtmlViewer;
