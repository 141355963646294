import { Skeleton } from "@mui/material";
import { LoadingSkeleton } from "Components";
import { FC, Suspense } from "react";

export const withLoading = <P extends object>(
  WrappedComponent: FC<P>,
  type: number = 1
): FC<P> => {
  const Loading: FC = () => {
    switch (type) {
      case 1:
        return <Skeleton variant="rounded" height={48} />;
      case 2:
        return <LoadingSkeleton p={2} />;
      default:
        return <div />;
    }
  };

  const ComponentWithLoading: FC<P> = (props) => {
    return (
      <Suspense fallback={<Loading />}>
        <WrappedComponent {...props} />
      </Suspense>
    );
  };

  return ComponentWithLoading;
};
