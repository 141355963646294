import { SVGProps } from "react";

export function ThunderIcon({
  width = 51,
  height = 51,
  viewBox = "0 0 51 51",
  stroke = "#019C3C",
  strokeWidth = 3.3,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M27.7435 4.74332L6.91016 29.7433H25.6602L23.5768 46.41L44.4102 21.41H25.6602L27.7435 4.74332Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
