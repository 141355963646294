import { LOGOUT_USER, SET_USER } from "redux/actions/actionTypes";

const initialState = {
  customer: null,
  customerToken: null,
  company: null,
  isSubscribe: false,
  user: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_COMPANY":
      return {
        ...state,
        company: action.payload,
      };

    case "RESET_CUSTOMER":
      return {
        ...state,
        customer: null,
        customerToken: null,
      };

    case "SET_CUSTOMER":
      return {
        ...state,
        customer: action.payload,
      };

    case "SET_CUSTOMER_TOKEN":
      return {
        ...state,
        customerToken: action.payload,
      };

    case "SET_IS_SUBSCRIBE":
      return {
        ...state,
        isSubscribe: action.payload,
      };

    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case LOGOUT_USER:
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
};

export default userReducer;
