export const COMMON_DATA_ACTIONS = {
  setUnseenData: "SET_UNSEEN_DATA",
  setMenuCountData: "SET_MENU_COUNT_DATA",
  reloadMenuCountData: "RELOAD_MENU_COUNT_DATA",
};

export const setUnSeenData = (data: any) => {
  return {
    type: COMMON_DATA_ACTIONS.setUnseenData,
    payload: data,
  };
};

export const setMenuCountData = (data: any) => {
  return {
    type: COMMON_DATA_ACTIONS.setMenuCountData,
    payload: data,
  };
};

export const onReloadMenuCountData = () => {
  return {
    type: COMMON_DATA_ACTIONS.reloadMenuCountData,
  };
};
