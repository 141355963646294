import { Box, Button, styled } from "@mui/material";
import { useIntl } from "react-intl";
import messages from "./messages";
import IntlMsg from "Components/IntlMsg";

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  "& button": {
    textTransform: "initial",
    padding: "2px 16px",
  },
});

const ConfirmBtns = (props) => {
  const intl = useIntl();
  const {
    acceptText = intl.formatMessage(messages.accept),
    acceptEvent = <IntlMsg id="Common.save" />,
    acceptProps,
    cancelText = <IntlMsg id="Common.cancel2" />,
    cancelEvent,
    att,
  } = props;
  return (
    <StyledBox sx={{ ...att }}>
      <Button onClick={cancelEvent} className="cancel">
        {cancelText}
      </Button>

      <Button onClick={acceptEvent} className="accept" {...acceptProps}>
        {acceptText}
      </Button>
    </StyledBox>
  );
};

export default ConfirmBtns;
