import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  receiptBoxItem: {
    padding: "26px 0px 12px",
  },
  dot: {
    height: "20px",
    width: "20px",
    backgroundColor: "#008934",
    borderRadius: "50%",
    display: "inline-block",
  },
  line: {
    height: "1px",
    borderBottom: "1px solid #828282",
  },
}));
