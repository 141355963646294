import { APITemplate } from "API/Api";
// import { detectDevice } from "../utils/handles";

const searchParams = new URLSearchParams(window.location.search);
const tableId = searchParams.get("tableId") || searchParams.get("tableid");

const pickCart = (carts) => {
  APITemplate(
    "freshdi.data.web.order.cart.pick_up_all_cart",
    {
      tmp: [],
      table: tableId,
      customer: localStorage.getItem("customerId"),
      order_data: carts?.map((item) => {
        return {
          ...item,
          topping_ids: item?.toppings?.map((topping) => {
            return topping?.name;
          }),
        };
      }),
      device_name: "",
      // device_name: detectDevice(window.navigator.userAgent)
    },
    (response) => {},
    (error) => {
      console.log(error);
    }
  );
};

const initialState = {
  carts: [],
  numberCart: 0,
  products: [],
  showCart: false,
  selectedItem: null,
};

const CartsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADDPEND_CART": {
      let newCarts = [];
      action.payload?.map((attribute) => {
        if (attribute?.quantity > 0) {
          let findIndex = state?.carts?.findIndex(
            (item) =>
              item?.name === attribute?.name && item?.note === attribute?.note
          );
          if (findIndex >= 0) {
            let indexItem = state.carts[findIndex];
            state.carts[findIndex] = {
              ...indexItem,
              quantity: indexItem?.quantity + attribute?.quantity,
            };
          } else {
            newCarts.push(attribute);
          }
        }
        return attribute;
      });

      pickCart([...state.carts, ...newCarts]);
      return {
        ...state,
        carts: [...state.carts, ...newCarts],
      };
    }

    case "SET_SHOW_CART":
      return {
        ...state,
        showCart: action.payload,
      };

    case "UPDATE_SAME_ITEM":
      let newCarts = state?.carts
        ?.filter((item) => item?.item_id !== action.payload.item_id)
        ?.concat(action.payload?.items || []);

      pickCart(newCarts);

      return {
        ...state,
        carts: newCarts,
      };

    case "RESET_CART":
      return {
        ...state,
        carts: [],
        numberCart: 0,
      };

    case "REPLACE_CART":
      return {
        ...state,
        carts: action.payload,
      };

    case "REMOVE_ITEM": {
      let newCarts = state.carts.filter(
        (item) =>
          item?.name !== action.payload?.name ||
          item?.note !== action.payload.note
      );
      console.log(newCarts);
      pickCart(newCarts);
      return {
        ...state,
        carts: newCarts,
      };
    }

    case "SET_SELECTED_ITEM":
      return {
        ...state,
        selectedItem: action.payload,
      };

    case "DESC_QTY": {
      let newCarts = state.carts
        .map((item) => {
          if (
            item?.name === action.payload.name &&
            item?.note === action.payload?.note &&
            JSON.stringify(item?.toppings) ===
              JSON.stringify(action.payload?.toppings)
          ) {
            return { ...item, quantity: item.quantity - 1 };
          }
          return item;
        })
        .filter((item) => item.quantity > 0);
      pickCart(newCarts);

      return {
        ...state,
        carts: newCarts,
        numberCart: state?.numberCart - 1,
      };
    }

    case "INSC_QTY": {
      // Check and update item
      let item_exists = false;
      let newCarts = state.carts.map((item) => {
        if (
          item.name === action.payload?.name &&
          item?.note === action.payload?.note &&
          JSON.stringify(item?.toppings) ===
            JSON.stringify(action.payload?.toppings)
        ) {
          item_exists = true;
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
      if (!item_exists) {
        newCarts.push({ ...action.payload, quantity: 1 });
      }

      pickCart(newCarts);
      return {
        ...state,
        carts: newCarts,
        numberCart: state?.numberCart + 1,
      };
    }

    case "CHANGE_NOTE": {
      // Check and update item
      let newCarts = state.carts.map((item) => {
        if (
          item.name === action.payload?.item?.name &&
          JSON.stringify(item?.toppings) === JSON.stringify(action.payload?.item?.toppings)
        ) {
          return {
            ...item,
            note: action.payload.note
          };
        }
        return item;
      });
      pickCart(newCarts);

      return {
        ...state,
        carts: newCarts,
      };
    }

    default:
      return state;
  }
};

export default CartsReducer;
