import React from 'react';
import { Typography } from '@mui/material'; 
import useStyles from './styles';

const Title = ({ title, align, att }) => {
  const classes = useStyles();

  return (
    <Typography variant="body1" className={classes.title} sx={{
      textAlign: align,
      fontWeight: 600,
      fontSize: {xs: 16, md: 18},
      ...att
    }}>
      { title }
    </Typography>
  )
}

export default Title;