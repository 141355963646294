import { Box, BoxProps, Divider, Typography, styled } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  text?: ReactNode;
  maxLineWidth?: string | number;
};

const TextOnLine = ({ text, maxLineWidth, ...props }: Props & BoxProps) => {
  return (
    <StyledBox maxLineWidth={maxLineWidth} py={1} {...props}>
      <Divider />

      <Typography variant="body2" className="text">
        {text}
      </Typography>
    </StyledBox>
  );
};

const StyledBox = styled(Box)(
  ({ maxLineWidth }: { maxLineWidth?: string | number }) => ({
    position: "relative",
    "&>*": {
      color: "#98A2B3",
    },
    ".MuiDivider-root": {
      maxWidth: maxLineWidth || "100%",
      margin: "0 auto",
    },
    "& .text": {
      position: "absolute",
      right: "50%",
      top: "calc(-50% + 16px)",
      padding: "0 16px",
      backgroundColor: "#fff",
      transform: "translate(50%, -50%)",
      fontWeight: 500,
    },
  })
);

export default TextOnLine;
