import { styled } from "@mui/material";
import Row from "./Row";

const StyledRFQHeader = styled(Row)(({ theme }) => ({
  padding: "16px 0",
  zIndex: 1,
  backgroundColor: "#fff",
  justifyContent: "space-between",
  ".title": {
    fontSize: 24,
    fontWeight: 600,
  },
  ".filter_btn": {
    border: "1px solid #D0D5DD",
    fontSize: 14,
  },
  [theme.breakpoints.down("md")]: {
    ".title": {
      fontSize: 18,
    },
  },
}));

export default StyledRFQHeader;
