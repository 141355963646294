import { defineMessages } from "react-intl";

export default defineMessages({
  accept: {
    id: "Common.accept",
    defaultMessage: "Đồng ý",
  },
  cancel: {
    id: "Common.cancel2",
    defaultMessage: "Bỏ qua",
  },
});
