import { useDispatch, useSelector } from "react-redux";

import { useLanguageContext } from "../LanguageProvider";
import { getUser } from "redux/selectors/user";
import { apiUpdateUser } from "services/api/user";
import { useLoading } from "providers/loading";
import { setUser } from "redux/actions/user";
import { authStorage } from "helpers/localStorage";
import { COUNTRIES } from "constants/data";
import { useMemo } from "react";
import useAuth from "hooks/useAuth";
import { langStorage } from "helpers/localStorage";

const useSelectLanguage = () => {
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const { language, setLanguage } = useLanguageContext();
  const user = useSelector(getUser);
  const { isLoggedIn } = useAuth();
  const auth = authStorage;

  const onSetLanguage = (language) => {
    dispatch(setUser({ ...user, language }));
    setLanguage(language);
  };

  // Xử lý thay đổi ngôn ngữ
  const onChangeLanguage = (language) => {
    if (isLoggedIn) {
      setLoading(true);
      apiUpdateUser({ language, token: auth.get("token") })
        .then((res) => {
          setLoading(false);
          onSetLanguage(language);
        })
        .catch((err) => setLoading(false));
    } else {
      onSetLanguage(language);
    }
    langStorage.set("selected", language);
  };

  const languageLabel = useMemo(() => {
    let currentCountry = COUNTRIES.find(
      (country) => country.value === language
    );
    return currentCountry ? currentCountry.label : null;
  }, [language, COUNTRIES]);

  return {
    language,
    countries: COUNTRIES,
    onChangeLanguage,
    languageLabel,
  };
};

export default useSelectLanguage;
