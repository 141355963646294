import { Box, Stack, styled } from "@mui/material";
import { Img } from "./Img";
import { BuyerType } from "types/common";
import { forwardRef } from "react";
import { StyledA, StyledATag, Text, XsText, XsTextMd } from "./Text";
import CountryFlag from "./CountryFlag";
// import { SendInquiryButton } from "./Button";
import { useIntl } from "react-intl";
import { formatImgUrl } from "utils/helpers";
import { SOURCING_DOMAIN } from "constants/schemas";
import { CrownTooltip, Tooltip, VerifyTooltip } from "./Tooltip";
import TimeDiff from "./TimeDiff";
import dayjs from "dayjs";
import { SendMessageButton } from "./Button";
import Row from "./Row";
import { Email2Icon, PhoneIcon } from "Assets/icons";

type Props = {
  buyer?: BuyerType;
  showfullflag?: boolean;
};

const Buyer = forwardRef(({ buyer, showfullflag }: Props, ref: any) => {
  const t = useIntl();

  const {
    is_featured,
    country,
    country_code,
    slug,
    company_logo,
    verified,
    categories,
    num_posted_rfq = 0,
    company_name,
    chat_available = 0,
    last_updated,
    identity,
    is_verified_email,
    is_verified_phone,
    // rfq_title,
    // rfq_image,
    // rfq_id,
  } = buyer || {};
  const NOW = dayjs();

  return (
    <StyledATag href={`${SOURCING_DOMAIN}/${slug}`}>
      <StyledBuyer ref={ref}>
        <Stack className="info_box" spacing={1}>
          <Stack direction="row" spacing={1} height={40}>
            {company_logo && (
              <Img
                src={formatImgUrl(company_logo, 3)}
                alt="img"
                className="image"
              />
            )}

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={0.75}
            >
              <StyledA>
                <XsTextMd className="cutoff-text oneline">
                  {company_name}
                </XsTextMd>
              </StyledA>
              {is_featured === 1 && (
                <CrownTooltip
                  title={t.formatMessage({
                    id: "this_is_a_premium_buyer",
                  })}
                />
              )}
              {verified === 1 && (
                <VerifyTooltip
                  title={t.formatMessage({
                    id: "buyer_is_verified_by_freshdi",
                  })}
                />
              )}
            </Stack>
          </Stack>

          <Stack direction="row" spacing={1} minHeight={24}>
            <CountryFlag
              {...{ country_code }}
              fd_country={country}
              className="cutoff-text oneline"
              style={{
                maxWidth: showfullflag || num_posted_rfq === 0 ? "auto" : 84,
                display: "-webkit-box",
                padding: "1px 2px",
              }}
            />
            {num_posted_rfq > 0 && (
              <XsTextMd className="rfq-posted cutoff-text oneline">
                {t.formatMessage(
                  { id: "qlt_rfq_posted" },
                  { quantity: num_posted_rfq }
                )}
              </XsTextMd>
            )}
          </Stack>

          <Stack spacing={0.5}>
            <XsText className="cutoff-text oneline categories">
              {categories && t.formatMessage({ id: "products" }) + ": "}

              {categories}
            </XsText>

            <XsText className="active-text">
              {last_updated &&
                t.formatMessage(
                  { id: "active_value_ago" },
                  {
                    value: (
                      <TimeDiff
                        endTime={NOW.format()}
                        startTime={dayjs(last_updated).format()}
                      />
                    ),
                  }
                )}
            </XsText>
          </Stack>

          {chat_available !== 1 && (
            <Row pt={1} justifyContent="space-between" height={30}>
              <Row>
                {is_verified_phone === 1 && (
                  <Tooltip
                    title={t.formatMessage({
                      id: "buyer_prefers_to_contact_via_whatsApp",
                    })}
                  >
                    <div className="app-icon">
                      <PhoneIcon />
                      <Text className="prefer">
                        {t.formatMessage({ id: "prefer" })}
                      </Text>
                    </div>
                  </Tooltip>
                )}

                {is_verified_email === 1 && (
                  <Tooltip
                    title={t.formatMessage({
                      id: "buyer_prefers_to_contact_via_email",
                    })}
                  >
                    <div className="app-icon">
                      <Email2Icon />
                      <Text className="prefer">
                        {t.formatMessage({ id: "prefer" })}
                      </Text>
                    </div>
                  </Tooltip>
                )}
              </Row>
            </Row>
          )}
        </Stack>
      </StyledBuyer>
    </StyledATag>
  );
});

const StyledBuyer = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: "#fff",
  overflow: "hidden",
  position: "relative",
  border: "1px solid rgba(0, 0, 0, 0.15)",
  cursor: "pointer",
  height: "100%",
  width: "100%",
  "& .medias": {
    height: 182,
    img: {
      width: "100%",
      height: "100%",
    },
  },
  // height: 341,
  "& .image": {
    height: 40,
    width: 40,
    objectFit: "cover",
  },
  "& .info_box": {
    padding: 8,
    ".rfq-posted": {
      color: "#C4320A",
      backgroundColor: "#FFF6ED",
      borderRadius: 16,
      padding: "2px 8px",
    },
    ".country_flag img": {
      marginRight: 4,
    },
    "& .sponsored": {
      backgroundColor: "#fff",
    },
    "& .verified_icon": {
      padding: 4,
      border: "1px solid #EAECF0",
      borderRadius: 6,
      height: 24,
      boxSizing: "border-box",
    },
    "& .address": {
      color: "#9D9D9D",
    },
    "& .avatar": {
      width: 64,
      height: 64,
      objectFit: "cover",
      borderRadius: "50%",
    },
    ".active-text": {
      fontSize: 11,
      color: "var(--green-700)",
      fontStyle: "italic",
      height: 18,
    },
    ".chat-box": {
      height: 40,
      button: {
        padding: "4px 16px",
        width: "100%",
        svg: {
          height: 30,
          width: 30,
        },
      },
    },
    ".post-box": {
      borderRadius: 4,
      border: "0.5px solid var(--gray-300)",
      cursor: "pointer",
      img: {
        width: 24,
        height: 24,
      },
      ".post-title": {
        fontSize: 10,
        color: "var(--gray-800)",
      },
      ".post-time": {
        fontSize: 8,
        color: "var(--gray-600)",
        fontStyle: "italic",
      },
      ".post-svg": {
        padding: "0 6px",
        cursor: "pointer",
      },
    },
  },
  "& .trust_icon": {
    position: "absolute",
    top: 0,
    right: 0,
    width: 40,
  },
  "& .categories": {
    color: "var(--gray)",
    minHeight: 18,
  },
  ".infos-text": {
    height: 36,
    color: "var(--gray)",
  },
  "& .send-inquiry-btn": {
    fontSize: "calc(100% * 0.85)",
    padding: 6,
  },

  [theme.breakpoints.down("md")]: {
    // height: 244,
    "& .image": {
      // height: 124,
    },
    ".chat-box": {
      button: {
        fontSize: 10,
        svg: {
          height: 20,
          width: 20,
        },
      },
    },
  },
}));

export default Buyer;
