import { Box, Stack, styled } from "@mui/material";
import { Img } from "./Img";
import { CompanyType } from "types";
import { formatImgUrl } from "utils/helpers";
import { forwardRef, useMemo, useRef } from "react";
import { StyledRouterA, XsText, XsTextMd } from "./Text";
import CountryFlag from "./CountryFlag";
import { useIntl } from "react-intl";
import { Swiper } from "swiper/react";
import { SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import defaultImage from "Assets/images/default_img.webp";
import { VerifyTooltip, LevelTooltip, VerifySupplierTooltip } from "./Tooltip";
import { SUPPLIER_PLANS } from "constants/data";

type Props = {
  company?: CompanyType;
  directFull?: boolean;
};

const Company = forwardRef(({ company }: Props, ref: any) => {
  const intl = useIntl();
  const {
    farm_name,
    fd_country,
    country_code,
    slug = "",
    farm_avatar,
    categories,
    gallery_preview,
    verification_level,
    plan_name,
  } = company || {};

  const swiperRef = useRef<any>(null);

  const handleSlideChange = (swiper: any) => {
    swiperRef.current.swiper.allowTouchMove =
      swiper.isBeginning && swiper.isEnd;
  };

  const showRealMedia = useMemo(
    () => gallery_preview && gallery_preview.length > 0,
    [gallery_preview]
  );

  const medias = useMemo(
    () => (showRealMedia ? gallery_preview : [defaultImage]),
    [showRealMedia]
  );

  // const onClickChat = (e: MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault();
  //   if (identity) {
  //     onAddChatToShowList(identity);
  //   }
  // };

  const currentPlan = useMemo(() => {
    return SUPPLIER_PLANS.find((plan) => plan.label === plan_name);
  }, [plan_name]);

  return (
    <StyledCompany ref={ref}>
      <Swiper
        onSlideChange={handleSlideChange}
        className="medias"
        modules={[Pagination]}
        pagination={{
          clickable: true,
        }}
      >
        {medias?.map((media, i) => (
          <SwiperSlide key={i}>
            <Img
              src={showRealMedia ? formatImgUrl(media, 3) : media}
              alt="media"
              height="100%"
              width="100%"
              style={{ objectFit: "cover" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Stack className="info_box" spacing={1}>
        <Stack direction="row" spacing={1}>
          <Img src={formatImgUrl(farm_avatar, 3)} alt="img" className="image" />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={0.75}
          >
            <StyledRouterA to={`/${slug}`}>
              <XsTextMd className="cutoff-text oneline" mr={1} component="h2">
                {farm_name}
              </XsTextMd>
            </StyledRouterA>
            {currentPlan?.icon && (
              <VerifyTooltip
                title={intl.formatMessage({
                  id: "this_is_a_premium_supplier",
                })}
              >
                <Img alt="icon" src={currentPlan?.icon} />
              </VerifyTooltip>
            )}
            <VerifySupplierTooltip
              title={<LevelTooltip level={verification_level} />}
              level={verification_level}
            />
          </Stack>
        </Stack>

        <Stack direction="row" spacing={1} minHeight={24}>
          <CountryFlag {...{ fd_country, country_code }} />
        </Stack>

        <XsText className="ellipsis-text categories" component="h3">
          {categories && intl.formatMessage({ id: "products" }) + ": "}

          {categories}
        </XsText>
      </Stack>
    </StyledCompany>
  );
});

const StyledCompany = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: "#fff",
  overflow: "hidden",
  position: "relative",
  border: "1px solid rgba(0, 0, 0, 0.15)",
  height: "100%",
  width: "100%",
  "& .medias": {
    height: 182,
    img: {
      width: "100%",
      height: "100%",
    },
  },
  "& .image": {
    height: 16,
    width: 16,
    objectFit: "cover",
  },
  "& .info_box": {
    padding: 8,
    "& .sponsored": {
      backgroundColor: "#fff",
    },
    "& .verified_icon": {
      padding: 4,
      border: "1px solid #EAECF0",
      borderRadius: 6,
      height: 24,
      boxSizing: "border-box",
    },
    "& .address": {
      color: "#9D9D9D",
    },
    "& .avatar": {
      width: 64,
      height: 64,
      objectFit: "cover",
      borderRadius: "50%",
    },
  },
  "& .trust_icon": {
    position: "absolute",
    top: 0,
    right: 0,
    width: 40,
  },
  "& .categories": {
    color: "var(--gray)",
    minHeight: 18,
  },
  "& .send-inquiry-btn": {
    fontSize: "calc(100% * 0.85)",
    padding: 6,
  },
  ".chat-icon": {
    padding: 0,
    "&:hover": {
      backgroundColor: "var(--green-50)",
    },
  },

  "& .swiper-pagination-bullet-active": {
    backgroundColor: "#ddd",
  },

  [theme.breakpoints.down("md")]: {
    "& .image": {},
    "& .send-inquiry-btn": {
      fontSize: 12,
      padding: 4,
    },
  },
}));

export default Company;
