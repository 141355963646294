export const FREE_BENEFIT_IMG = require("./benefit/free.svg").default;
export const FIRST_LEADING_BENEFIT_IMG =
  require("./benefit/first_leading.webp").default;
export const APPLY_TECH_BENEFIT_IMG =
  require("./benefit/apply_tech.webp").default;
export const SOURCING_SERVICE_IMG =
  require("./service/sourcing_service.png").default;
export const DEFAULT_WALLPAPER_IMG =
  require("./default_wallpaper.webp").default;
export const EN_HOMEPAGE_1 = require("./Eng_Homepage_1.webp").default;
export const VI_HOMEPAGE_1 = require("./Viet_Homepage_1.webp").default;
export const EN_HOMEPAGE_2 = require("./Eng_Homepage_2.webp").default;
export const VI_HOMEPAGE_2 = require("./Viet_Homepage_2.webp").default;
export const EN_HOMEPAGE_3 = require("./Eng_Homepage_3.webp").default;
export const VI_HOMEPAGE_3 = require("./Viet_Homepage_3.webp").default;
export const AI_PROCESSING_IMG = require("./ai_processing.gif").default;
export const EN_COMPARE_PRICING =
  require("./Eng_ComparePricing_Homepage.webp").default;
export const VI_COMPARE_PRICING =
  require("./Viet_ComparePricing_Homepage.webp").default;
export const BUY_PLAN_BANNER_EN_DESKTOP_IMG =
  require("./banner/buy_plan_banner_en_desktop.webp").default;
export const BUY_PLAN_BANNER_EN_MOBILE_IMG =
  require("./banner/buy_plan_banner_en_mobile.webp").default;
export const BUY_PLAN_BANNER_VN_DESKTOP_IMG =
  require("./banner/buy_plan_banner_vn_desktop.webp").default;
export const BUY_PLAN_BANNER_VN_MOBILE_IMG =
  require("./banner/buy_plan_banner_vn_desktop.webp").default;
export const BANNER_1 = require("./Banner_1.webp").default;
export const VI_BANNER_1 = require("./Viet_Banner_1.webp").default;
export const BANNER_2 = require("./Banner_2.webp").default;
export const VI_BANNER_2 = require("./Viet_Banner_2.webp").default;
export const BANNER_3 = require("./Banner_3.webp").default;
export const VI_BANNER_3 = require("./Viet_Banner_3.webp").default;
export const SUPPLIER_BG_EN_DESKTOP =
  "/images/background/supplier_bg_en_desktop.webp";
export const SUPPLIER_BG_VN_DESKTOP =
  "/images/background/supplier_bg_vn_desktop.webp";
export const SUPPLIER_BG_EN_MOBILE =
  "/images/background/supplier_bg_en_mobile.webp";
export const SUPPLIER_BG_VN_MOBILE =
  "/images/background/supplier_bg_vn_mobile.webp";

export const HOME_BANNER_EN_DESKTOP_4 =
  "/images/banner/home_banner_en_desktop_4.webp";
export const HOME_BANNER_VN_DESKTOP_4 =
  "/images/banner/home_banner_vn_desktop_4.webp";
export const HOME_BANNER_EN_MOBILE_4 =
  "/images/banner/home_banner_en_mobile_4.webp";
export const HOME_BANNER_VN_MOBILE_4 =
  "/images/banner/home_banner_vn_mobile_4.webp";
export const HOME_BANNER_ZH_DESKTOP_4 =
  "/images/banner/home_banner_zh_desktop_4.webp";
export const HOME_BANNER_ZH_MOBILE_4 =
  "/images/banner/home_banner_zh_mobile_4.webp";
export const HOME_BANNER_EN_DESKTOP_5 =
  "/images/banner/home_banner_en_desktop_5.webp";
export const HOME_BANNER_VN_DESKTOP_5 =
  "/images/banner/home_banner_vn_desktop_5.webp";
export const HOME_BANNER_EN_MOBILE_5 =
  "/images/banner/home_banner_en_mobile_5.webp";
export const HOME_BANNER_VN_MOBILE_5 =
  "/images/banner/home_banner_vn_mobile_5.webp";
export const HOME_BANNER_ZH_DESKTOP_5 =
  "/images/banner/home_banner_zh_desktop_5.webp";
export const HOME_BANNER_ZH_MOBILE_5 =
  "/images/banner/home_banner_zh_mobile_5.webp";

export const TESTIMONIALS_LOGO_1 = "/images/common/Les Artisans du Monde.png";

export const TESTIMONIALS_LOGO_2 = "/images/common/Bruckmann GmbH.jpeg";

export const TESTIMONIALS_LOGO_3 =
  "/images/common/Interfresh Food and Beverage Company Limited.png";

export const TESTIMONIALS_LOGO_4 =
  "/images/common/Garden-to-global-exports-pvt-ltd.jpeg";

export const TESTIMONIALS_LOGO_5 = "/images/common/VERTEKS-MVE-NIG-LTD.jpg";

export const TESTIMONIALS_LOGO_6 =
  "/images/common/Guangdong Meteor Century International Trade Co.png";

export const TESTIMONIALS_LOGO_7 = "/images/common/PT-Najah-Triumph-Optima.png";

export const TESTIMONIALS_LOGO_8 =
  "/images/common/Akpobi-and-sons-enterprise.jpg";

export const TESTIMONIALS_LOGO_9 =
  "/images/common/Favdip-Global-Resources-Ltd.jpg";

export const TESTIMONIALS_LOGO_10 =
  "/images/common/Công ty TNHH Chế Biến Nông Hải Sản Biển Xanh.png";

export const TESTIMONIALS_LOGO_11 =
  "/images/common/Chukwubiko and sons global Limited .jpg";

export const TESTIMONIALS_LOGO_12 = "/images/common/pongsamutthara_co.png";
export const TESTIMONIALS_LOGO_13 = "/images/common/trusted_clients_13.png";
export const TESTIMONIALS_LOGO_14 = "/images/common/trusted_clients_14.png";
export const TESTIMONIALS_LOGO_15 = "/images/common/trusted_clients_15.png";

type BannerPaths = {
  [key: string]: string;
};

type BannersConfig = {
  desktop: BannerPaths;
  mobile: BannerPaths;
};

export const APP_IMGS: Record<"zh" | "vi" | "en" | "common", BannersConfig> = {
  zh: {
    desktop: {
      backgroundLogin: "/images/zh/China_login.webp",
      bannerPush: "/images/zh/home_banner_desktop.webp",
      bannerPush2: "/images/zh/home_banner_desktop_2.webp",
      bannerPush3: "/images/zh/home_banner_desktop_3.webp",
      bannerRegister: "/images/zh/register_banner_desktop.webp",
      bannerNewArrivals: "/images/zh/banner_new_arrival_desktop.webp",
      bannerFreshdiChoice: "/images/zh/banner_freshdi_choice_desktop.webp",
      registerHowWork: "/images/zh/register_howwork_desktop.webp",
      bannerRegisterBuyer: "/images/zh/register_buyer_banner.webp",
      bannerRegisterSupplier: "/images/zh/register_supplier_banner.webp",
    },
    mobile: {
      bannerPush: "/images/zh/home_banner_mobilie.webp",
      bannerPush2: "/images/zh/home_banner_mobile_2.webp",
      bannerPush3: "/images/zh/home_banner_mobile_3.webp",
      bannerRegister: "/images/zh/register_banner_mobile.webp",
      bannerNewArrivals: "/images/zh/banner_new_arrival_mobile.webp",
      bannerFreshdiChoice: "/images/zh/banner_freshdi_choice_mobile.webp",
    },
  },
  vi: {
    desktop: {
      backgroundLogin: "/images/vi/Viet_login.webp",
      bannerPush: "/images/vi/home_banner_desktop.webp",
      bannerPush2: "/images/vi/home_banner_desktop_2.webp",
      bannerPush3: "/images/vi/home_banner_desktop_3.webp",
      bannerRegister: "/images/vi/register_banner_desktop.webp",
      bannerNewArrivals: "/images/vi/banner_new_arrival_desktop.webp",
      bannerFreshdiChoice: "/images/vi/banner_freshdi_choice_desktop.webp",
      registerHowWork: "/images/vi/register_howwork_desktop.webp",
      bannerRegisterBuyer: "/images/vi/register_buyer_banner.webp",
      bannerRegisterSupplier: "/images/vi/register_supplier_banner.webp",
    },
    mobile: {
      bannerPush: "/images/vi/home_banner_mobile.webp",
      bannerPush2: "/images/vi/home_banner_mobile_2.webp",
      bannerPush3: "/images/vi/home_banner_mobile_3.webp",
      bannerRegister: "/images/vi/register_banner_mobile.webp",
      bannerNewArrivals: "/images/vi/banner_new_arrival_mobile.webp",
      bannerFreshdiChoice: "/images/vi/banner_freshdi_choice_mobile.webp",
    },
  },
  en: {
    desktop: {
      backgroundLogin: "/images/en/Eng_login.webp",
      bannerPush: "/images/en/home_banner_desktop.webp",
      bannerPush2: "/images/en/home_banner_desktop_2.webp",
      bannerPush3: "/images/en/home_banner_desktop_3.webp",
      bannerRegister: "/images/en/register_banner_desktop.webp",
      bannerNewArrivals: "/images/en/banner_new_arrival_desktop.webp",
      bannerFreshdiChoice: "/images/en/banner_freshdi_choice_desktop.webp",
      registerHowWork: "/images/en/register_howwork_desktop.webp",
      bannerRegisterBuyer: "/images/en/register_buyer_banner.webp",
      bannerRegisterSupplier: "/images/en/register_supplier_banner.webp",
    },
    mobile: {
      bannerPush: "/images/en/home_banner_mobile.webp",
      bannerPush2: "/images/en/home_banner_mobile_2.webp",
      bannerPush3: "/images/en/home_banner_mobile_3.webp",
      bannerRegister: "/images/en/register_banner_mobile.webp",
      bannerNewArrivals: "/images/en/banner_new_arrival_mobile.webp",
      bannerFreshdiChoice: "/images/en/banner_freshdi_choice_mobile.webp",
    },
  },
  common: {
    desktop: {
      bannerContactUs: "/images/common/banner_contact_us.png",
    },
    mobile: {
      // bannerContactUs: "/images/common/banner_contact_us.png",
    },
  },
};
