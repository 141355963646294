import { getCountryFlag } from "helpers/address";
import { Img } from "./Img";
import { Typography, TypographyProps, styled } from "@mui/material";

const CountryFlag = ({
  fd_country,
  country_code,
  country,
  ...props
}: TypographyProps & {
  fd_country?: string;
  country_code?: string;
  country?: string;
}) => {
  if (!(fd_country || country)) return null;

  return (
    <StyledCompanyFlag {...props} className={"country_flag " + props.className}>
      {country_code && (
        <Img src={getCountryFlag(country_code).src} alt="flag" />
      )}
      {fd_country || country}
    </StyledCompanyFlag>
  );
};

const StyledCompanyFlag = styled(Typography)({
  fontSize: 12,
  fontWeight: 500,
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  gap: 6,
  padding: "0 2px",
  backgroundColor: "#fff",
  borderRadius: 4,
  border: "0.5px solid #EAECF0",
  img: {
    width: 12,
    height: 9,
    borderRadius: 8,
  },
});

export default CountryFlag;
