import { Box, styled } from "@mui/material";
import { ConfirmButton, LgTextSemiBold, SmText } from "Components";
import { memo } from "react";
import { useIntl } from "react-intl";

type Props = {
  onClose: () => void;
};

const Disclaimer = ({ onClose }: Props) => {
  const intl = useIntl();
  return (
    <StyledDisclaimer>
      <LgTextSemiBold>
        {intl.formatMessage({ id: "disclaimer" })}
      </LgTextSemiBold>

      <SmText color="var(--gray)">
        {intl.formatMessage({ id: "disclaimer_desc" })}
      </SmText>

      <Box textAlign="right" mt={3}>
        <ConfirmButton style={{ width: "fit-content" }} onClick={onClose}>
          {intl.formatMessage({ id: "ok" })}
        </ConfirmButton>
      </Box>
    </StyledDisclaimer>
  );
};

const StyledDisclaimer = styled(Box)({
  padding: 24,
});

export default memo(Disclaimer);
