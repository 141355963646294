import { SVGProps } from "react";

export function GiftIcon({
  width = 51,
  height = 51,
  viewBox = "0 0 51 51",
  stroke = "#019C3C",
  strokeWidth = 3.3,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M41.9961 25.5766V46.41H8.66276V25.5766M25.3294 46.41V15.16M25.3294 15.16H15.9544C14.5731 15.16 13.2483 14.6112 12.2716 13.6345C11.2948 12.6577 10.7461 11.333 10.7461 9.95165C10.7461 8.57031 11.2948 7.24555 12.2716 6.2688C13.2483 5.29205 14.5731 4.74332 15.9544 4.74332C23.2461 4.74332 25.3294 15.16 25.3294 15.16ZM25.3294 15.16H34.7044C36.0858 15.16 37.4105 14.6112 38.3873 13.6345C39.364 12.6577 39.9128 11.333 39.9128 9.95165C39.9128 8.57031 39.364 7.24555 38.3873 6.2688C37.4105 5.29205 36.0858 4.74332 34.7044 4.74332C27.4128 4.74332 25.3294 15.16 25.3294 15.16ZM4.49609 15.16H46.1628V25.5766H4.49609V15.16Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
