import { TextField, styled } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme }) => ({
  minHeight: 48,
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "1px solid #E0E0E0",
    },
  },
  "& .MuiInputBase-root:hover": {
    border: "initial",
  },
  "& .MuiInputLabel-root": {
    // color: "#000 !important"
  },
  "& .MuiFormLabel-root": {
    paddingRight: "8px",
    backgroundColor: "#fff",
  },
  "& .MuiInputBase-input": {
    height: "initial",
  },
  "& input, .MuiSelect-select": {
    padding: "14px",
  },
  "& input, textarea, textarea::placeholder, .MuiInputBase-root": {
    fontSize: 14,
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  },
  "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
}));

export default StyledTextField;
