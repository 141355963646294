import { SVGProps } from "react";

export function TargetIcon({
  width = 18,
  height = 18,
  viewBox = "0 0 18 18",
  fill = "#F79009",
  stroke = "#ADB3BC",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M8.16667 5.74997C8.58333 5.66663 8.91667 5.16663 8.75 4.74997C8.66667 4.3333 8.16667 3.99997 7.75 4.16663C5.5 4.66663 4 6.66663 4 8.99997C4 11.75 6.25 14 9 14C11.5833 14 13.75 12 14 9.41663C14.0833 8.99997 13.6667 8.5833 13.25 8.49997C12.8333 8.41663 12.4167 8.8333 12.3333 9.24997C12.1667 10.9166 10.6667 12.3333 9 12.3333C7.16667 12.3333 5.66667 10.8333 5.66667 8.99997C5.66667 7.41663 6.66667 6.0833 8.16667 5.74997Z"
        fill={fill}
      />
      <path
        d="M16.5 8.16657C16 8.16657 15.6667 8.4999 15.6667 8.9999C15.6667 12.6666 12.6667 15.6666 9.00002 15.6666C5.33335 15.6666 2.33335 12.6666 2.33335 8.9999C2.33335 5.4999 5.08335 2.58324 8.58335 2.33324C9.08335 2.33324 9.41669 1.91657 9.33335 1.41657C9.33335 0.916571 8.91669 0.583237 8.41669 0.666571C4.08335 0.999904 0.666687 4.58324 0.666687 8.9999C0.666687 13.5832 4.41669 17.3332 9.00002 17.3332C13.5834 17.3332 17.3334 13.5832 17.3334 8.9999C17.3334 8.4999 16.9167 8.16657 16.5 8.16657Z"
        fill={fill}
      />
      <path
        d="M10.5834 6.24996L8.41669 8.41663C8.08335 8.74996 8.08335 9.24996 8.41669 9.58329C8.58335 9.74996 8.83335 9.83329 9.00002 9.83329C9.16669 9.83329 9.41669 9.74996 9.58335 9.58329L11.75 7.41663L13.1667 7.83329C13.25 7.83329 13.3334 7.83329 13.4167 7.83329C13.6667 7.83329 13.8334 7.74996 14 7.58329L17.25 4.24996C17.4167 4.08329 17.5 3.74996 17.5 3.49996C17.5 3.24996 17.25 2.99996 17 2.91663L15.5 2.41663L15 0.916626C14.9167 0.666626 14.6667 0.416626 14.4167 0.416626C14.1667 0.333293 13.8334 0.416626 13.6667 0.666626L10.4167 3.99996C10.1667 4.24996 10.0834 4.58329 10.25 4.83329L10.5834 6.24996ZM13.8334 2.83329L14.0834 3.41663C14.1667 3.66663 14.3334 3.83329 14.5834 3.91663L15.1667 4.16663L13.1667 6.16663L12.1667 5.83329L11.8334 4.83329L13.8334 2.83329Z"
        fill={fill}
      />
    </svg>
  );
}
