import { Box, Dialog, Divider, IconButton, NoSsr, Stack } from "@mui/material";

import {
  StyledCenterStack,
  StyledHeader,
  StyledMobileHeader,
} from "./Header.styles";
import LogoFreshdi from "./LogoFreshdi";
import { PATHS } from "constants/routes";
import { memo, Suspense, useEffect, useState } from "react";
import AuthBar from "./AuthBar";

import IntlMsg from "./IntlMsg";
import Row from "./Row";
import { Container } from "./Container";
import {
  GetQuotesButton,
  HeaderChat,
  HeaderServices,
  Img,
  Link,
  Notification,
  RegisterButton,
  SelectLanguage,
  SellingButton,
  SmTextMd,
  XlTextSemiBold,
} from "Components";
import useAuth from "hooks/useAuth";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { IoMdMenu } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { POST_RFQ } from "../Assets/icons";
import HeaderSearch from "./HeaderSearch";
import HeaderLinks from "./HeaderLinks";
import { useIntl } from "react-intl";
import { COLORS } from "constants/layout";

type Props = {
  hideSearch?: boolean;
  isWorkSpace?: boolean;
  isHomePage?: boolean;
};

const Header = ({ hideSearch, isWorkSpace, isHomePage }: Props) => {
  const t = useIntl();
  const { isMobile } = useMediaBreakpoints();
  const { isLoggedIn } = useAuth();
  const [showDialog, setShowDialog] = useState(false);
  const [isBottomBoxVisible, setIsBottomBoxVisible] = useState(true);

  const onClickMenu = () => {
    setShowDialog(true);
  };

  const onCloseDrawer = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsBottomBoxVisible(false);
      } else {
        setIsBottomBoxVisible(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <NoSsr>
      <StyledHeader>
        <Container>
          <StyledCenterStack
            justifyContent="space-between"
            px={{ xs: 0, md: 2 }}
          >
            {isMobile && (
              <Row spacing={1}>
                <IconButton onClick={onClickMenu}>
                  <IoMdMenu />
                </IconButton>
                <SelectLanguage
                  buttonProps={{ sx: { justifyContent: "flex-start" } }}
                  showLabel={false}
                />
              </Row>
            )}
            {isMobile && <LogoFreshdi />}
            {!isMobile && (
              <Row spacing={2} flex={1}>
                <LogoFreshdi />
                {isWorkSpace && (
                  <XlTextSemiBold fontStyle="italic" color={COLORS.green[600]}>
                    {t.formatMessage({ id: "buyer_workspace" })}
                  </XlTextSemiBold>
                )}
                {!hideSearch && !isHomePage && <HeaderSearch />}
              </Row>
            )}
            {!isMobile && (
              <StyledCenterStack justifyContent="flex-end">
                <GetQuotesButton />
                <SelectLanguage buttonProps={{ className: "app-link-btn" }} />
                <Row ml={1}>
                  {isLoggedIn && (
                    <Suspense fallback={null}>
                      <Notification />
                      <HeaderChat />
                    </Suspense>
                  )}
                  <AuthBar />
                  {!isLoggedIn && <RegisterButton />}
                </Row>
              </StyledCenterStack>
            )}
            {isMobile && (
              <Row spacing={1.5} minWidth={96} justifyContent="flex-end">
                {isLoggedIn && (
                  <>
                    <Notification />
                    <HeaderChat />
                  </>
                )}
                <AuthBar hideUserName />
              </Row>
            )}
          </StyledCenterStack>
          {!hideSearch && isMobile && <HeaderSearch sx={{ mt: 1 }} />}
        </Container>

        {/* Bottom header */}
        {!isMobile && (
          <>
            <Divider sx={{ mt: 2 }} />
            <Container
              className={
                "bottom-box " + (isBottomBoxVisible ? "visible" : "hidden")
              }
            >
              <Row justifyContent="space-between">
                <HeaderLinks />
                <HeaderServices />
              </Row>
            </Container>
          </>
        )}
        <Dialog fullScreen open={showDialog} onClose={onCloseDrawer}>
          <MobileHeader onClose={onCloseDrawer} />
        </Dialog>
      </StyledHeader>
    </NoSsr>
  );
};

const MobileHeader = ({ onClose }: { onClose?: any }) => {
  const { isLoggedIn } = useAuth();
  const t = useIntl();

  return (
    <StyledMobileHeader>
      <Row justifyContent="space-between" p={2} pb={1}>
        <IconButton onClick={onClose}>
          <MdClose />
        </IconButton>
        <LogoFreshdi />
        <AuthBar hideUserName={true} />
      </Row>

      <Stack spacing={2} className="mobileheader">
        <Divider />
        <Stack spacing={2} p={2}>
          <HeaderLinks inrow />
          <Link to={PATHS.quickRfq} onClick={onClose}>
            <SmTextMd className="quickrfq_button">
              <Img src={POST_RFQ} alt="post rfq" />
              {t.formatMessage({ id: "quick_post_rfq" })}
            </SmTextMd>
          </Link>
          <SellingButton className="selling-btn" />
        </Stack>
        <Divider />

        <Stack spacing={2} p={2}>
          <Box mx={isLoggedIn ? "0" : "auto"}>
            <AuthBar isMobileView />
          </Box>
          {!isLoggedIn && <RegisterButton />}
        </Stack>
      </Stack>
    </StyledMobileHeader>
  );
};

export default memo(Header);
