export const IMAGE_TYPES = ["png", "jpg", "jpeg", "webp"];

export const checkFileIsImage = (fileUrl?: string) => {
  let fileType = (fileUrl || "").split(".").pop();
  return fileType && IMAGE_TYPES.indexOf(fileType) > -1;
};

export const createDownloadLink = (
  url: string,
  filename: string,
  callback?: () => void
) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  callback?.();
};
