import { useMediaQuery } from "@mui/material";
import { BREAKPOINT_VALUES } from "constants/layout";
import { useEffect, useMemo, useState } from "react";

const useMediaBreakpoints = () => {
  const mobilePoint = BREAKPOINT_VALUES.sm;
  const tabletPoint = BREAKPOINT_VALUES.md;

  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  const downMobileBreakpoint = useMediaQuery(`(max-width:${mobilePoint}px)`, {
    noSsr: true,
  });
  const downTabletBreakpoint = useMediaQuery(`(max-width:${tabletPoint}px)`, {
    noSsr: true,
  });

  const isMobile = hydrated && downTabletBreakpoint;

  return useMemo(
    () => ({
      downMobileBreakpoint,
      downTabletBreakpoint,
      isMobile,
    }),
    [downMobileBreakpoint, downTabletBreakpoint, hydrated]
  );
};

export default useMediaBreakpoints;
